import * as React from 'react';

import Box            from '@material-ui/core/Box';
import Button         from '@material-ui/core/Button';
import Fade           from '@material-ui/core/Fade';
import FormControl    from '@material-ui/core/FormControl';
import Grid           from '@material-ui/core/Grid';
import TextField      from '@material-ui/core/TextField';
import useMediaQuery  from '@material-ui/core/useMediaQuery';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import PasswordInput   from '@components/common/form/PasswordInput';
import RegionSelection from '@components/common/form/RegionSelection';
import withUserAccount from '@components/User/withUserAccount';

import LoadingIndicator   from '@components/common/LoadingIndicator';
import FormHelperText     from '@styles/form/FormHelperText';
import PageGridItem       from '@styles/PageGridItem';
import Text               from '@styles/Text';
import DefaultTheme       from '@themes/DefaultTheme';

import { WithUserAccountInterface } from '@components/User/WithUserAccountInterface';
import Link                         from '@material-ui/core/Link';

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  emailNotice: {
    marginTop: spacing(1),
    marginBottom: spacing(2)
  },
  form: {
    marginTop: spacing(5)
  },
  headingBox: {
    marginBottom: spacing(2)
  },
  roboresContainer: {
    overflow: 'hidden'
  },
  robores: {
    left: '120vh',
    height: '10px'
  },
  submitBtn: {
    marginTop: spacing(2)
  }
}));

const SignUp = React.forwardRef((props: WithUserAccountInterface, ref: React.Ref<HTMLFormElement>): JSX.Element => {
  const classes = useStyles();
  const isMobileResolution = useMediaQuery('(max-width:767px)');

  const {
    csrfToken,
    errors,
    formAction,
    onChange,
    onRegionChange,
    onSave,
    user,
    saving
  } = props;

  return (
    <PageGridItem>
      <form
        action={formAction}
        className={classes.form}
        method="post"
        onSubmit={onSave}
        ref={ref}
        noValidate
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className={classes.headingBox}>
              <Text type="heading">
                Sign Up
              </Text>
            </Box>

            <Text type="displayXs">
              Already signed up?
              {' '}
              <Link
                href="/users/login"
              >
                Log In
              </Link>
              .
            </Text>

            <Text type="displayXs">
              Fill out the form below to get started.
            </Text>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              error={errors.username.length > 0}
              fullWidth
            >
              <TextField
                error={errors.username.length > 0}
                name="user[username]"
                id="username"
                label="Username"
                type="text"
                onChange={onChange}
                value={user.username}
                variant="outlined"
                required
              />

              {errors.username.length > 0 && (
                <Fade in>
                  <FormHelperText>
                    {errors.username}
                  </FormHelperText>
                </Fade>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              error={errors.email.length > 0}
              fullWidth
            >
              <TextField
                error={errors.email.length > 0}
                name="user[email]"
                id="email"
                label="Email Address"
                type="email"
                onChange={onChange}
                value={user.email}
                variant="outlined"
                required
              />

              <Text type="regular" className={classes.emailNotice}>
                Please enter a valid email address. You will need to confirm your account through a link sent to your inbox.
              </Text>

              {errors.email.length > 0 && (
                <Fade in>
                  <FormHelperText>
                    {errors.email}
                  </FormHelperText>
                </Fade>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              error={errors.region.length > 0}
              fullWidth
            >
              <RegionSelection
                onChange={onRegionChange}
                value={user.region}
              />

              {errors.region.length > 0 && (
                <Fade in>
                  <FormHelperText>
                    {errors.region}
                  </FormHelperText>
                </Fade>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <PasswordInput
              fieldId="password"
              fieldName="user[password]"
              fieldLabel="Password"
              fieldValue={user.password}
              onChangeHandler={onChange}
              error={errors.password}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordInput
              fieldId="password_confirmation"
              fieldName="user[password_confirmation]"
              fieldLabel="Confirm Password"
              fieldValue={user.password_confirmation}
              onChangeHandler={onChange}
              error={errors.password_confirmation}
              required
            />

            <input type="hidden" name="authenticity_token" value={csrfToken} />
          </Grid>
          <Grid item xs={12}>
            <Text type="displayXs">
              By signing up, you confirm your agreement to our
              {' '}
              <Link href="/disclaimer">
                disclaimer
              </Link>
              {' '}
              and
              {' '}
              <Link href="/privacy">
                privacy policy
              </Link>
              .
            </Text>
          </Grid>
          <Grid item xs={12} className={classes.roboresContainer}>
            <Button
              className={classes.submitBtn}
              color="primary"
              disabled={saving}
              fullWidth={isMobileResolution}
              onClick={onSave}
              type="submit"
              variant="contained"
              size="large"
            >
              {saving && <LoadingIndicator />}
              Sign up
            </Button>

            <TextField
              name="user[music_allergy]"
              id="music_allergy"
              label="Are you allergic to any kind of music?"
              type="text"
              onChange={onChange}
              value={user.musicAllergy}
              className={classes.robores}
            />
          </Grid>
        </Grid>
      </form>
    </PageGridItem>
  );
});

export default withUserAccount(SignUp);
