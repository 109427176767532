import * as React from 'react';

import Box                from '@material-ui/core/Box';
import ClickAwayListener  from '@material-ui/core/ClickAwayListener';
import IconButton         from '@material-ui/core/IconButton';
import Popper             from '@material-ui/core/Popper';
import WatchIcon          from '@material-ui/icons/WatchLater';
import {
  createStyles,
  makeStyles
} from '@material-ui/styles';

import DefaultTheme from '@themes/DefaultTheme';
import Text         from '@themes/styles/Text';

import { AppStatsInterface } from '@components/common/AppStatsInterface';

interface QueueStatsIndicatorProps {
  appStats: AppStatsInterface;
}

const useStyles = makeStyles(({ colors, spacing }: DefaultTheme) => createStyles({
  label: {
    border: '1px solid',
    marginTop: spacing(2),
    padding: spacing(2),
    backgroundColor: colors.lightBlack,
  },
  queueLowIcon: {
    fill: colors.green,
    fontSize: '25px',
    opacity: '90%'
  },
  queueMediumIcon: {
    fill: colors.honey,
    fontSize: '25px',
    opacity: '90%'
  },
  queueHighIcon: {
    fill: colors.lightRed,
    fontSize: '25px'
  },
  inlineItem: {
    display: 'inline-block',
    marginRight: spacing(3)
  }
}));

export default function QueueStatsIndicator(props: QueueStatsIndicatorProps): JSX.Element {
  const classes = useStyles();
  const { appStats } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'queue-stats' : undefined;

  let queueLoadChipClass = classes.queueLowIcon;
  let queueLoadInfo = 'Low queue. Your requests should not take long to process.';

  if (appStats) {
    if (appStats.queueLoad === 'moderate') {
      queueLoadChipClass = classes.queueMediumIcon;
      queueLoadInfo = 'Moderate queue. Your requests might take a while to process.';
    } else if (appStats.queueLoad === 'high') {
      queueLoadChipClass = classes.queueHighIcon;
      queueLoadInfo = 'Lenghty queue. Your requests may take a couple of hours to process.';
    }
  }

  return (
    <Box className={classes.inlineItem} style={{ marginRight: 0 }}>
      <IconButton onClick={handleClick}>
        <WatchIcon className={queueLoadChipClass} fontSize="medium" />
      </IconButton>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={handleClick}>
          <Box className={classes.label}>
            <Text type="label">{queueLoadInfo}</Text>
          </Box>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
}
