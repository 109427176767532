import * as React from 'react';

import Grid from '@material-ui/core/Grid';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import DefaultTheme from '@themes/DefaultTheme';

interface Props {
  children: JSX.Element;
  className?: string;
}

const useStyles = makeStyles(({ breakpoints }: DefaultTheme) => createStyles({
  root: {
    padding: '0 10px',
    margin: '0 auto',
    height: '100%',
    width: '100%',

    [breakpoints.down('sm')]: {
      margin: '0 auto',
      padding: '0 15px'
    },

    [breakpoints.between('sm', 'md')]: {
      width: '768px',
      padding: '0 30px'
    },

    [breakpoints.up('md')]: {
      width: '960px'
    },

    [breakpoints.up('lg')]: {
      width: 'auto',
      maxWidth: '1500px'
    }
  }
}));

export default function ResponsiveGridItem({ children, className }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Grid item className={`${classes.root} ${className}`}>
      {children}
    </Grid>
  );
}

ResponsiveGridItem.defaultProps = {
  className: ''
};
