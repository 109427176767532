import * as React from 'react';

import Box    from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid   from '@material-ui/core/Grid';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import HomeGallery        from '@components/Home/HomeGallery';
import ResponsiveGridItem from '@styles/ResponsiveGridItem';
import Text               from '@styles/Text';
import DefaultTheme       from '@themes/DefaultTheme';

const useStyles = makeStyles(({ spacing, colors }: DefaultTheme) => createStyles({
  introText: {
    marginBottom: spacing(4)
  },
  galleryBox: {
    marginTop: '40px'
  },
  promoBox: {
    backgroundColor: colors.lightBlack,
    marginBottom: spacing(5),
    padding: spacing(5)
  },
  promoGrid: {
    marginTop: spacing(2)
  },
  textBox: {
    margin: '10px 0 20px 0'
  }
}));

export default function Home(): JSX.Element {
  const classes = useStyles();

  return (
    <ResponsiveGridItem>
      <>
        <Box className={classes.promoBox}>
          <Grid container>
            <Grid item xs={12} className={classes.introText}>
              <Text type="displayMd">
                Manage your playlists
              </Text>

              <Text type="displayXs" color="grey" className={classes.textBox}>
                Connect to your favorite music streaming services, transfer your music playlists between them, easily generate backups, and more.
              </Text>

              <Button
                color="primary"
                href="/users/sign_up"
                size="large"
                variant="contained"
              >
                <Text type="displayXs" weight={500}>
                  Get Started
                </Text>
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.galleryBox}>
          <HomeGallery />
        </Box>
      </>
    </ResponsiveGridItem>
  );
}
