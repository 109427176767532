import * as React from 'react';

import Accordion        from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon   from '@material-ui/icons/ExpandMore';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import TrackList from '@components/Summaries/common/TrackList';
import { ExportPlaylistSummaryInterface } from '@components/Summaries/Export/ExportSummaryInterface';

import Text         from '@styles/Text';
import DefaultTheme from '@themes/DefaultTheme';

interface ExportedPlaylistSummaryProps {
  playlist: ExportPlaylistSummaryInterface;
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  trackCount: {
    marginRight: spacing(2)
  }
}));

export default function ExportedPlaylistSummary({ playlist }: ExportedPlaylistSummaryProps): JSX.Element {
  const classes     = useStyles();
  const { summary } = playlist;

  const exportedBlock = (
    <Accordion expanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Text type="displayXs" color="green" weight={700} className={classes.trackCount}>
          {summary.exported.total}
        </Text>
        <Text type="displayXs">
          Tracks imported
        </Text>
      </AccordionSummary>
      <AccordionDetails>
        <Text type="label">
          <a href={`/playlists/${playlist.id}`}>
            See original playlist for full track list
          </a>
        </Text>
      </AccordionDetails>
    </Accordion>
  );

  const notFoundBlock = summary.notFound.total === 0
    ? null
    : (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Text type="displayXs" color="green" weight={700} className={classes.trackCount}>
            {summary.notFound.total}
          </Text>
          <Text type="displayXs">
            Tracks could not be found
          </Text>
        </AccordionSummary>
        <AccordionDetails>
          <TrackList trackStats={summary.notFound} />
        </AccordionDetails>
      </Accordion>
    )
  ;

  const notExactMatchBlock = summary.notExactMatch.total === 0
    ? null
    : (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Text type="displayXs" color="green" weight={700} className={classes.trackCount}>
            {summary.notExactMatch.total}
          </Text>
          <Text type="displayXs">
            Not exact match
          </Text>
        </AccordionSummary>
        <AccordionDetails>
          <TrackList trackStats={summary.notExactMatch} />
        </AccordionDetails>
      </Accordion>
    )
  ;

  return (
    <>
      {exportedBlock}
      {notFoundBlock}
      {notExactMatchBlock}
    </>
  );
}
