import * as React from 'react';

import DeleteIcon     from '@material-ui/icons/Delete';
import IconButton     from '@material-ui/core/IconButton';
import Toolbar        from '@material-ui/core/Toolbar';
import Tooltip        from '@material-ui/core/Tooltip';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import Text from '@styles/Text';
import DefaultTheme from '@themes/DefaultTheme';

interface TableToolbarProps {
  numSelected: number;
  onDeleteClick: () => void;
  toolbarTitle?: string;
}

const useStyles = makeStyles(({ palette, spacing }: DefaultTheme) => createStyles({
  root: {
    paddingLeft: spacing(2),
    paddingRight: spacing(1),
  },
  highlight: {
    color: palette.text.primary,
    backgroundColor: palette.secondary.dark,
  },
  title: {
    flex: '1 1 100%',
  },
}));

export default function TableToolbar({
  numSelected,
  onDeleteClick,
  toolbarTitle = ''
}: TableToolbarProps): JSX.Element {
  const classes = useStyles();
  const toolbarClasses = `${classes.root} ${numSelected > 0 ? classes.highlight : ''}`;

  let view = null;

  if (numSelected > 0) {
    view = (
      <Toolbar className={toolbarClasses}>
        {numSelected > 0 ? (
          <Text type="displayXs" className={classes.title}>
            {`${numSelected} selected`}
          </Text>
        ) : (
          <Text type="displayXs" className={classes.title}>
            {toolbarTitle}
          </Text>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete" onClick={onDeleteClick}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </Toolbar>
    );
  }

  return view;
}
