import * as React from 'react';

import Box                from '@material-ui/core/Box';
import Divider            from '@material-ui/core/Divider';
import Hidden             from '@material-ui/core/Hidden';
import List               from '@material-ui/core/List';
import ListItem           from '@material-ui/core/ListItem';
import MenuList           from '@material-ui/core/MenuList';
import MenuItem           from '@material-ui/core/MenuItem';
import Grow               from '@material-ui/core/Grow';
import Paper              from '@material-ui/core/Paper';
import Popper             from '@material-ui/core/Popper';
import ClickAwayListener  from '@material-ui/core/ClickAwayListener';
import {
  createStyles,
  makeStyles
} from '@material-ui/styles';

import AuthApi                from '@api/AuthApi';
import NotificationsMenu      from '@components/Notifications/NotificationsMenu';
import Greeting               from '@components/Header/Greeting';
import { MenuItemInterface }  from '@components/Header/MenuItems/MenuItemInterface';

import ArrowDropDown  from '@styles/icons/ArrowDropDown';
import NavButton      from '@styles/NavButton';
import Text           from '@styles/Text';
import DefaultTheme   from '@themes/DefaultTheme';

import QueueStatsIndicator   from '@components/Header/QueueStatsIndicator';
import { AppStatsInterface } from '@components/common/AppStatsInterface';
import { NotificationCollectionInterface } from '@components/Notifications/NotificationCollectionInterface';

interface UserMenuItemsProps {
  appStats: AppStatsInterface;
  notificationCollection?: NotificationCollectionInterface;
  username: string;
}

const useStyles = makeStyles(({ colors, spacing }: DefaultTheme) => createStyles({
  arrowDown: {
    marginTop: '6px'
  },
  paper: {
    backgroundColor: colors.lightBlack
  },
  menuDivider: {
    margin: '5px 0'
  },
  inlineItem: {
    display: 'inline-block',
    marginRight: spacing(3)
  }
}));

const items: Array<MenuItemInterface> = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    path: '/'
  },
  {
    key: 'manage',
    label: 'Manage Playlists',
    path: '/playlists'
  },
  {
    key: 'integrations',
    label: 'Integrations',
    path: '/integrations'
  }
];

const accountItems: Array<MenuItemInterface> = [
  {
    key: 'account',
    label: 'My Account',
    path: '/account'
  },
  {
    key: 'signout',
    label: 'Sign out',
    path: '/users/sign_out'
  }
];

export default function UserMenuItems(props: UserMenuItemsProps): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const open    = Boolean(anchorEl);

  const handleMenu = (event): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleItemClick = (event, key): void => {
    if (key === 'signout') {
      event.preventDefault();

      AuthApi
        .logout()
        .then(() => {
          window.location.href = '/';
        })
      ;
    }
  };

  const {
    appStats,
    notificationCollection,
    username
  } = props;

  return (
    <>
      <Hidden xsDown>
        <QueueStatsIndicator appStats={appStats} />

        <Box className={classes.inlineItem}>
          <NotificationsMenu notificationCollection={notificationCollection} />
        </Box>

        <NavButton onClick={handleMenu}>
          <Greeting username={username} />
          <ArrowDropDown
            className={classes.arrowDown}
            opacity="0.7"
            open={open}
          />
        </NavButton>
        <Popper open={open} anchorEl={anchorEl} transition disablePortal>
          {({ TransitionProps }) => (
            <Grow
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...TransitionProps}
              style={{ transformOrigin: 'center bottom' }}
            >
              <Paper id="menu-list-grow" className={classes.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} onClick={handleClose}>
                    {items.map((item: MenuItemInterface): JSX.Element => (
                      <MenuItem
                        component="a"
                        key={item.key}
                        onClick={(e): void => handleItemClick(e, item.key)}
                        href={item.path}
                      >
                        <Text color="lightGrey" type="label" weight={400}>
                          {item.label}
                        </Text>
                      </MenuItem>
                    ))}

                    <Divider className={classes.menuDivider} />

                    {accountItems.map((item: MenuItemInterface): JSX.Element => (
                      <MenuItem
                        component="a"
                        key={item.key}
                        onClick={(e): void => handleItemClick(e, item.key)}
                        href={item.path}
                      >
                        <Text color="lightGrey" type="label" weight={400}>
                          {item.label}
                        </Text>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Hidden>
      <Hidden smUp>
        <Divider />
        <List>
          {items.map((item: MenuItemInterface): JSX.Element => (
            <ListItem
              button
              component="a"
              key={item.key}
              onClick={(e): void => handleItemClick(e, item.key)}
              href={item.path}
            >
              <Text color="lightGrey" type="label" weight={400}>
                {item.label}
              </Text>
            </ListItem>
          ))}

          <Divider />

          {accountItems.map((item: MenuItemInterface): JSX.Element => (
            <ListItem
              button
              component="a"
              key={item.key}
              onClick={(e): void => handleItemClick(e, item.key)}
              href={item.path}
            >
              <Text color="lightGrey" type="label" weight={400}>
                {item.label}
              </Text>
            </ListItem>
          ))}
        </List>
      </Hidden>
    </>
  );
}
