import * as React from 'react';

import ExportSummaryView          from '@components/Summaries/Export/ExportSummaryView';
import ExportSummaryMultiView     from '@components/Summaries/Export/ExportSummaryMultiView';
import { ExportSummaryInterface } from '@components/Summaries/Export/ExportSummaryInterface';
import { UserInterface }          from '@components/User/UserInterface';

interface ExportSummaryProps {
  exportSummary: ExportSummaryInterface
  user: UserInterface;
}

export default class ExportSummary extends React.Component<ExportSummaryProps> {
  render(): React.ReactNode {
    const {
      exportSummary
    } = this.props;

    const viewProps = {
      summary: exportSummary
    };

    return exportSummary.playlists.length > 1
      ? (<ExportSummaryMultiView {...viewProps} />)
      : (<ExportSummaryView {...viewProps} />)
    ;
  }
}
