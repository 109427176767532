import * as React from 'react';

import Box        from '@material-ui/core/Box';
import Divider    from '@material-ui/core/Divider';
import Drawer     from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Grid       from '@material-ui/core/Grid';
import MenuIcon   from '@material-ui/icons/Menu';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import { UserInterface } from '@components/User/UserInterface';

import DefaultTheme from '@themes/DefaultTheme';
import CloseIcon    from '@styles/icons/Close';
import Text         from '@styles/Text';

interface MobileMenuViewProps {
  children: React.ReactNode;
  onMobileMenuToggle: () => void;
  user?: UserInterface;
  visible: boolean;
}

const useStyles = makeStyles(({ colors }: DefaultTheme) => createStyles({
  greeting: {
    padding: '10px',
    textAlign: 'left'
  },
  paper: {
    backgroundColor: colors.lightBlack,
    color: colors.white,
    borderLeft: 'none',
    width: '100%'
  }
}));

export default function MobileMenuView(props: MobileMenuViewProps): JSX.Element {
  const {
    children,
    onMobileMenuToggle,
    user,
    visible
  } = props;

  const classes = useStyles();

  let greeting = 'Playlists.cloud';

  if (user && user.username) {
    greeting = `Hello ${user.username}`;
  }

  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={onMobileMenuToggle}
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        anchor="right"
        classes={{ paper: classes.paper }}
        open={visible}
        variant="persistent"
      >
        <Grid container>
          <Grid item xs={10}>
            <Box className={classes.greeting}>
              <Text type="Label" weight={500} color="grey">
                {greeting}
              </Text>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <CloseIcon
              color="white"
              right="10px"
              top="10px"
              position="absolute"
              onClick={onMobileMenuToggle}
            />
          </Grid>
        </Grid>

        <Divider />

        <div
          role="presentation"
          onClick={onMobileMenuToggle}
        >
          {children}
        </div>
      </Drawer>
    </>
  );
}
