import * as React from 'react';

import Box            from '@material-ui/core/Box';
import Button         from '@material-ui/core/Button';
import InputLabel     from '@material-ui/core/InputLabel';
import FormControl    from '@material-ui/core/FormControl';
import Select         from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

import { UserProvidersInterface } from '@components/User/UserInterface';
import { PlaylistInterface }      from '@components/Playlist/PlaylistInterface';
import UserContext                from '@contexts/UserContext';

import ContentActionDialog  from '@styles/dialogs/ContentActionDialog';
import Text                 from '@styles/Text';
import CompactSelectInput   from '@themes/styles/form/CompactSelectInput';
import DefaultTheme         from '@themes/DefaultTheme';

interface ExportToProviderViewProps {
  fromSourceInitialValue: string;
  hasOrphanPlaylists: boolean;
  onCloseExportDialog: () => void;
  onSubmitExportDialog: (fromSource?: string, toSource?: string) => void;
  open: boolean;
  playlistIds?: Array<PlaylistInterface['id']>;
  showFromSelection: boolean;
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => ({
  selectBox: {
    marginTop: spacing(3)
  },
  formControl: {
    marginBottom: spacing(1),
    width: (props: Partial<ExportToProviderViewProps>): string => (props.showFromSelection ? '50%' : '100%'),
    '& .MuiInputLabel-shrink': {
      marginTop: 0
    }
  },
  selectInput: {
    padding: 5
  },
  inputLabel: {
    marginTop: spacing(2)
  }
}));

export default function ExportToProviderView({
  fromSourceInitialValue,
  hasOrphanPlaylists,
  onCloseExportDialog,
  onSubmitExportDialog,
  open,
  playlistIds,
  showFromSelection
}: ExportToProviderViewProps): JSX.Element {
  const uploadProvider  = 'upload';

  const classes       = useStyles({ showFromSelection });
  const user          = React.useContext(UserContext);
  const userProviders = user.providers.filter((provider: UserProvidersInterface) => provider.source !== uploadProvider);

  const [fromProvider, setFromProvider] = React.useState(fromSourceInitialValue);
  const [toProvider, setToProvider]     = React.useState('');

  const multiplePlaylists = playlistIds.length > 1;
  const playlistWord      = `playlist${multiplePlaylists ? 's' : ''}`;

  const availableToProviders = () => {
    if (!fromProvider) {
      return userProviders;
    }

    return userProviders.filter((provider: UserProvidersInterface) => provider.source !== fromProvider);
  };

  const handleOnChangeFromProvider  = (e) => setFromProvider(e.target.value);
  const handleOnChangeToProvider    = (e) => setToProvider(e.target.value);

  const title = (
    <>
      {`Export ${playlistWord} to a provider`}
    </>
  );

  const subtitle = `Select a connected provider to export ${multiplePlaylists ? 'your playlists' : 'the selected playlist'} to.`;

  const canExport = availableToProviders().length > 0;
  const selectToProviderView = canExport ? (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel className={classes.inputLabel} htmlFor="select-to-provider">To provider</InputLabel>
      <Select
        native
        value={toProvider}
        className={classes.selectInput}
        onChange={handleOnChangeToProvider}
        input={<CompactSelectInput />}
        inputProps={{
          name: 'from',
          id: 'select-to-provider'
        }}
      >
        <option aria-label="None" value="" />
        {availableToProviders().map((provider: UserProvidersInterface) => (
          <option key={provider.source} value={provider.source}>
            {provider.name}
          </option>
        ))}
      </Select>
    </FormControl>
  ) : (
    <Text type="label" color="red">
      You must first connect to a provider.
    </Text>
  );

  const content = (
    <>
      <Text type="label">
        {subtitle}
      </Text>
      <Text type="label">
        Please notice that the selected provider may not have all the songs in the
        {` ${playlistWord}`}
        .
      </Text>
      {multiplePlaylists && (
        <Text type="label">
          This process can take a few minutes.
        </Text>
      )}
      <Box className={classes.selectBox}>
        {showFromSelection && (
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel className={classes.inputLabel} htmlFor="select-from-provider">From Provider</InputLabel>
            <Select
              native
              value={fromProvider}
              className={classes.selectInput}
              onChange={handleOnChangeFromProvider}
              input={<CompactSelectInput />}
              inputProps={{
                name: 'from',
                id: 'select-from-provider'
              }}
            >
              <option aria-label="None" value="" />
              {hasOrphanPlaylists && (
                <option value="orphan">
                  Orphan Playlists
                </option>
              )}

              {userProviders.map((provider: UserProvidersInterface) => (
                <option key={provider.source} value={provider.source}>
                  {provider.name}
                </option>
              ))}
            </Select>
          </FormControl>
        )}

        {selectToProviderView}
      </Box>
    </>
  );

  const action = (
    <Button
      autoFocus
      onClick={() => onSubmitExportDialog(fromProvider, toProvider)}
      disabled={!canExport}
    >
      Start Export
    </Button>
  );

  return (
    <ContentActionDialog
      onClose={onCloseExportDialog}
      open={open}
      title={title}
      content={content}
      action={action}
    />
  );
}
