import * as React from 'react';

import Card           from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader     from '@material-ui/core/CardHeader';
import CardContent    from '@material-ui/core/CardContent';
import Skeleton       from '@material-ui/lab/Skeleton';

type directionType = 'horizontal' | 'vertical';

interface LoadingCardProps {
  direction?: directionType;
}

export default class LoadingCard extends React.PureComponent<LoadingCardProps> {
  static defaultProps = {
    direction: 'vertical'
  }

  render(): JSX.Element {
    const {
      direction
    } = this.props;

    return direction === 'vertical'
      ? (
        <Card>
          <CardHeader
            subheader={<Skeleton height={20} />}
          />
          <CardActionArea>
            <Skeleton height={200} />
          </CardActionArea>
          <CardContent>
            <Skeleton height={20} />
          </CardContent>
        </Card>
      )
      : (
        <Card>
          <CardActionArea>
            <CardContent>
              <Skeleton height={20} />
              <Skeleton height={60} />
            </CardContent>
          </CardActionArea>
        </Card>
      )
    ;
  }
}
