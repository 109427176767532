export const BASE_URL = '/api/v1';

export interface genericApiResponse {
  status?: string;
  error?: string;
}

export const defaultApiErrorMessage = 'An internal error occurred, please try again later.';

export default class BaseApi {
  static csrfToken(): string {
    return document.querySelector<HTMLMetaElement>('meta[name="csrf-token"]').content;
  }
}
