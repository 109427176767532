import * as React from 'react';

import ClickAwayListener  from '@material-ui/core/ClickAwayListener';
import Button             from '@material-ui/core/Button';
import Grow               from '@material-ui/core/Grow';
import Paper              from '@material-ui/core/Paper';
import Popper             from '@material-ui/core/Popper';
import MenuItem           from '@material-ui/core/MenuItem';
import MenuList           from '@material-ui/core/MenuList';
import { makeStyles }     from '@material-ui/core/styles';
import BackupIcon         from '@material-ui/icons/Backup';

import { ExportTypes } from '@components/ExportPlaylists/ExportTypes';

interface ExportPlaylistsViewProps {
  editMode: boolean;
  onClickExportFile: (exportType: ExportTypes) => void;
  onClickExportToProvider: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  button: {
    marginRight: (props: Partial<ExportPlaylistsViewProps>): number => (props.editMode ? theme.spacing(2) : 0)
  },
  paper: {
    marginRight: theme.spacing(2)
  },
  popper: {
    zIndex: 100
  }
}));

export default function ExportPlaylistsView({
  editMode,
  onClickExportFile,
  onClickExportToProvider
}: ExportPlaylistsViewProps): JSX.Element {
  const classes = useStyles({ editMode });
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event, exportType: ExportTypes) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    if (exportType === ExportTypes.Providers) {
      onClickExportToProvider();
    } else if (exportType) {
      onClickExportFile(exportType);
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const button = (
    <Button
      ref={anchorRef}
      aria-controls={open ? 'menu-list-grow' : undefined}
      aria-haspopup="true"
      className={classes.button}
      onClick={handleToggle}
      variant={editMode ? 'contained' : 'text'}
      color="default"
      startIcon={<BackupIcon />}
    >
      {editMode ? 'Export Selected' : 'Export Playlists'}
    </Button>
  );

  const exportToProviderLabel = editMode ? 'To Provider' : 'Between providers';
  const exportToCsvLabel      = editMode ? 'To CSV' : 'All as CSV';
  const exportToJsonLabel     = editMode ? 'To JSON' : 'All as JSON';

  const popperBlock = (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      className={classes.popper}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper className={classes.paper}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                <MenuItem onClick={(e) => handleClose(e, ExportTypes.Providers)}>
                  {exportToProviderLabel}
                </MenuItem>
                <MenuItem onClick={(e) => handleClose(e, ExportTypes.Csv)}>
                  {exportToCsvLabel}
                </MenuItem>
                <MenuItem onClick={(e) => handleClose(e, ExportTypes.Json)}>
                  {exportToJsonLabel}
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );

  return editMode ? (
    <>
      {button}
      {popperBlock}
    </>
  ) : (
    <div className={classes.root}>
      {button}
      {popperBlock}
    </div>
  );
}
