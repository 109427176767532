import * as React from 'react';

import AmazonMusicProvider  from '@components/Providers/AmazonMusic/AmazonMusicProvider';
import AppleMusicProvider   from '@components/Providers/AppleMusic/AppleMusicProvider';
import DeezerProvider       from '@components/Providers/Deezer/DeezerProvider';
import SpotifyProvider      from '@components/Providers/Spotify/SpotifyProvider';

import {
  ProvidersInterface,
  ProviderComponentsType
} from '@components/Providers/ProvidersInterface';

export default function connectProviderComponents(providers: ProvidersInterface, ssoState: string = ''): ProviderComponentsType {
  return {
    amazonMusic: (
      <AmazonMusicProvider
        provider={providers.amazonMusic}
        ssoState={ssoState}
      />
    ),
    appleMusic: (
      <AppleMusicProvider provider={providers.appleMusic} />
    ),
    deezer: (
      <DeezerProvider
        provider={providers.deezer}
        ssoState={ssoState}
      />
    ),
    spotify: (
      <SpotifyProvider
        provider={providers.spotify}
        ssoState={ssoState}
      />
    )
  };
}
