import * as React from 'react';

import Button     from '@material-ui/core/Button';
import UploadIcon from '@material-ui/icons/Description';

export default function UploadPlaylistsButton(): JSX.Element {
  return (
    <Button
      variant="text"
      color="default"
      href="/imports/playlists"
      startIcon={<UploadIcon />}
    >
      Upload Playlists
    </Button>
  );
}
