import * as React from 'react';

import Grid from '@material-ui/core/Grid';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import DefaultTheme from '@themes/DefaultTheme';
import SplitButton, { SplitButtonInterface } from '@themes/styles/form/SplitButton';

interface ButtonVariantViewProps {
  items: Array<SplitButtonInterface>
}

const useStyles = makeStyles(({ breakpoints }: DefaultTheme) => createStyles({
  root: {
    [breakpoints.up('sm')]: {
      alignItems: 'flex-end'
    }
  }
}));

export default function ButtonVariantView({ items }: ButtonVariantViewProps): JSX.Element {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item xs={12}>
        <SplitButton items={items} />
      </Grid>
    </Grid>
  );
}
