import * as React from 'react';

import Skeleton   from '@material-ui/lab/Skeleton';
import TableRow   from '@material-ui/core/TableRow';
import TableCell  from '@material-ui/core/TableCell';

interface LoadingTableRowProps {
  cells: number;
  rows: number;
}

export default class LoadingTableRow extends React.PureComponent<LoadingTableRowProps> {
  static defaultProps = {
    cells: 1,
    rows: 1
  };

  render(): JSX.Element {
    const {
      cells,
      rows
    } = this.props;

    const tableRows = [];

    const cellsForRow = (rowIndex: number): Array<JSX.Element> => {
      const rowCells = [];

      for (let i = 0; i < cells; i++) {
        rowCells.push(
          <TableCell key={`tcell-${rowIndex}-${i}`}>
            <Skeleton />
          </TableCell>
        );
      }

      return rowCells;
    };

    for (let r = 0; r < rows; r++) {
      const rowCells = cellsForRow(r);

      tableRows.push(
        <TableRow key={`lrow-${r}`}>
          {rowCells}
        </TableRow>
      );
    }

    return tableRows;
  }
}
