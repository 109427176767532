import * as React from 'react';

import ConnectDeezer from '@components/Providers/Deezer/ConnectDeezer';
import { DeezerProviderInterface } from '@components/Providers/Deezer/DeezerProviderInterface';

interface DeezerProviderProps {
  provider: DeezerProviderInterface;
  ssoState: string;
}

export default function DeezerProvider({
  provider,
  ssoState
}: DeezerProviderProps): JSX.Element {
  return (
    <ConnectDeezer deezerProvider={provider} ssoState={ssoState} />
  );
}
