import * as React from 'react';

import Typography from '@material-ui/core/Typography';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import DefaultTheme from '@themes/DefaultTheme';

interface TextProps {
  color?: string;
  children: React.ReactNode;
  className?: string;
  component?: string;
  style?: Record<string, unknown>;
  type?: string;
  weight?: number|null;
}

interface Classes {
  common: string;
}

const useStyles = makeStyles(({ colors, breakpoints }: DefaultTheme) => createStyles({
  common: {
    color:      (props: TextProps) => (props.color ? colors[props.color] : colors.default),
    fontWeight: (props: TextProps) => (props.weight ? props.weight : null),
    '& a': {
      color: colors.primary
    }
  },

  heading: {
    fontWeight: 300,

    [breakpoints.down('xs')]: {
      fontSize:   '36px',
      lineHeight: '48px'
    },

    [breakpoints.up('sm')]: {
      fontSize:   '48px',
      lineHeight: '60px'
    }
  },

  displayMd: {
    fontWeight: 400,

    [breakpoints.down('xs')]: {
      fontSize:   '24px',
      lineHeight: '36x'
    },

    [breakpoints.up('sm')]: {
      fontSize:   '36px',
      lineHeight: '48px'
    }
  },

  displaySm: {
    fontWeight: 400,
    fontSize:   '24px',
    lineHeight: '36px'
  },

  displayXs: {
    fontWeight: 400,

    [breakpoints.down('xs')]: {
      fontSize:   '16px',
      lineHeight: '24px'
    },

    [breakpoints.up('sm')]: {
      fontSize:   '20px',
      lineHeight: '33px'
    }
  },

  label: {
    fontWeight: 400,
    fontSize:   '16px',
    lineHeight: '24px'
  },

  regular: {
    fontWeight: 300,
    fontSize:   '14px',
    lineHeight: '20px'
  },

  small: {
    fontWeight: 300,
    fontSize:   '12px',
    lineHeight: '16px'
  },

  mini: {
    fontWeight: 300,
    fontSize:   '10px',
    lineHeight: '12px'
  }
}));

const variations = (type: string, classes: Classes) => {
  const { common } = classes;
  const ct = classes[type];

  const component = {
    'heading':   'h1',
    'displayMd': 'h2'
  }[type] || 'p';

  return {
    className: `${common} ${ct}`,
    component
  };
};

export default function Text(props: TextProps): JSX.Element {
  const classes = useStyles(props);

  const {
    children,
    component,
    className,
    style,
    type
  } = props;

  const typographyProps = variations(type, classes);

  return (
    <Typography
      className={`${typographyProps.className} ${className}`}
      component={component || typographyProps.component}
      style={style}
    >
      {children}
    </Typography>
  );
}

Text.defaultProps = {
  color:      '',
  className:  '',
  component:  null,
  style:      {},
  type:       'regular',
  weight:     null
};
