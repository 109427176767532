import * as React from 'react';

import Box            from '@material-ui/core/Box';
import Fade           from '@material-ui/core/Fade';
import Grid           from '@material-ui/core/Grid';
import Button         from '@material-ui/core/Button';
import useMediaQuery  from '@material-ui/core/useMediaQuery';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import PasswordInput  from '@components/common/form/PasswordInput';
import DefaultTheme   from '@themes/DefaultTheme';
import FormHelperText from '@styles/form/FormHelperText';
import PageGridItem   from '@styles/PageGridItem';
import Text           from '@styles/Text';

import { ResetPasswordErrorsType } from '@components/Auth/ResetPasswordErrorsType';

interface ResetPasswordViewProps {
  csrfToken: string;
  errors: ResetPasswordErrorsType;
  formAction: string;
  onChange: (event: React.SyntheticEvent) => void;
  onSave: (event: React.SyntheticEvent) => void;
  password: string;
  passwordConfirmation: string;
  resetPasswordToken: string;
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  form: {
    marginTop: spacing(5)
  },
  gridSpacing: {
    marginTop: spacing(5)
  },
  headingBox: {
    marginBottom: spacing(2)
  },
  submitBtn: {
    marginTop: spacing(2)
  }
}));

const ResetPasswordView = React.forwardRef((props: ResetPasswordViewProps, ref: React.Ref<HTMLFormElement>): JSX.Element => {
  const classes = useStyles();
  const isMobileResolution = useMediaQuery('(max-width:767px)');

  const {
    csrfToken,
    errors,
    formAction,
    onChange,
    onSave,
    password,
    passwordConfirmation,
    resetPasswordToken
  } = props;

  return (
    <PageGridItem>
      <form
        action={formAction}
        className={classes.form}
        method="post"
        onSubmit={onSave}
        ref={ref}
        noValidate
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className={classes.headingBox}>
              <Text type="heading">
                Change your password
              </Text>
            </Box>
          </Grid>

          {errors.resetPasswordToken.length > 0 && (
            <Grid item xs={12}>
              <Fade in>
                <FormHelperText>
                  {errors.resetPasswordToken}
                </FormHelperText>
              </Fade>
            </Grid>
          )}

          <Grid item xs={12}>
            <PasswordInput
              fieldId="password"
              fieldName="user[password]"
              fieldLabel="Password"
              fieldValue={password}
              onChangeHandler={onChange}
              error={errors.password}
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordInput
              fieldId="passwordConfirmation"
              fieldName="user[password_confirmation]"
              fieldLabel="Confirm Password"
              fieldValue={passwordConfirmation}
              onChangeHandler={onChange}
              error={errors.passwordConfirmation}
            />

            <input type="hidden" name="authenticity_token" value={csrfToken} />
            <input type="hidden" value={resetPasswordToken} name="user[reset_password_token]" />
            <input type="hidden" name="_method" value="put" />
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.submitBtn}
              color="primary"
              fullWidth={isMobileResolution}
              onClick={onSave}
              type="submit"
              variant="contained"
              size="large"
            >
              Change my password
            </Button>
          </Grid>
        </Grid>
      </form>
    </PageGridItem>
  );
});

export default ResetPasswordView;
