import * as React from 'react';

import Box          from '@material-ui/core/Box';
import Breadcrumbs  from '@material-ui/core/Breadcrumbs';
import Grid         from '@material-ui/core/Grid';
import Link         from '@material-ui/core/Link';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import DefaultTheme   from '@themes/DefaultTheme';
import PageGridItem   from '@styles/PageGridItem';
import Text           from '@styles/Text';

interface TwoFactorBackupCodes {
  backupCodes: Array<string>;
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  qrCode: {
    backgroundColor: '#fff',
    padding: spacing(2),
    width: 300,
    marginTop: spacing(2)
  },
  form: {
    marginTop: spacing(4)
  },
  headingBox: {
    marginBottom: spacing(2)
  }
}));

export default function TwoFactorBackupCodes({ backupCodes }: TwoFactorBackupCodes): JSX.Element {
  const classes = useStyles();
  const pageTitle = '2-FA Enabled';

  return (
    <PageGridItem>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" href="/account">
              <Text type="label">
                My Account
              </Text>
            </Link>

            <Link color="inherit" href="/account/2fa">
              <Text type="label">
                Two-Factor Authentication
              </Text>
            </Link>

            <Text type="label" color="secondary">
              {pageTitle}
            </Text>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.headingBox}>
            <Text type="heading">
              {pageTitle}
            </Text>
          </Box>
          <Box className={classes.headingBox}>
            <Text type="label">
              Two-factor authentication is now enabled on your account.
            </Text>
          </Box>
          <Text type="label">
            Here are your backup codes for signing in to your account if you lose access to your two-factor authentication app. These codes are only visible now, so make sure to save them in a safe place for future use.
          </Text>
        </Grid>
        <Grid item xs={12}>
          {backupCodes.map((code: string) => (
            <Text type="regular">
              {code}
            </Text>
          ))}
        </Grid>
      </Grid>
    </PageGridItem>
  );
}
