import * as React from 'react';

import FlashContext         from '@contexts/FlashContext';
import NotificationsChannel from '@channels/NotificationsChannel';

import { NotificationCollectionInterface } from '@components/Notifications/NotificationCollectionInterface';

const notificationChannel = new NotificationsChannel();

export default function NotificationFlashes(): JSX.Element {
  const flash = React.useContext(FlashContext);
  const [cachedChecksum, setCachedChecksum] = React.useState('');
  const [cachedNotifications, setCachedNotifications] = React.useState([]);

  const onWatchSuccess = (notificationCollection: NotificationCollectionInterface): void => {
    const { checksum, data: serverNotifications } = notificationCollection;

    if (cachedChecksum === checksum) {
      return;
    }

    setCachedChecksum(checksum);
    setCachedNotifications(serverNotifications);

    const cachedNotificationsIds = cachedNotifications.map((n) => n.attributes.id);
    const newNotifications = serverNotifications.filter((n) => !cachedNotificationsIds.includes(n.attributes.id));

    let flashableSet = newNotifications;

    // Detect updated notifications
    if (newNotifications.length === 0 && cachedNotifications.length > 0) {
      const cachedNotificationsStages = {};
      cachedNotifications.forEach((n) => {
        cachedNotificationsStages[n.attributes.id] = n.attributes.stage;
      });

      flashableSet = serverNotifications.filter((n): boolean => {
        const nId = n.attributes.id;

        if (nId in cachedNotificationsStages) {
          return cachedNotificationsStages[nId] !== n.attributes.stage;
        }

        return true;
      });
    }

    flashableSet.forEach((notification) => {
      if (notification.attributes.stage === 0) {
        return;
      }

      let flashType = 'success';
      let flashMessage = notification.attributes.content;

      if (notification.attributes.stage === 2) {
        flashType = 'error';
        flashMessage = notification.attributes.errorMessage;
      }

      flash.flashMessage([flashType, flashMessage]);
    });
  };

  React.useEffect(() => {
    notificationChannel.subscribe(onWatchSuccess);

    return function cleanup() {
      notificationChannel.unsubscribe();
    };
  });

  return (<></>);
}
