import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  /* barlow-semi-condensed - latin */
  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-style: normal;
    font-weight: 300;
    src: local(''),
         url('/fonts/barlow-semi-condensed-v6-latin-300.woff2') format('woff2'),
         url('/fonts/barlow-semi-condensed-v6-latin-300.woff') format('woff');
  }
  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-style: normal;
    font-weight: 400;
    src: local(''),
         url('/fonts/barlow-semi-condensed-v6-latin-regular.woff2') format('woff2'),
         url('/fonts/barlow-semi-condensed-v6-latin-regular.woff') format('woff');
  }
  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-style: normal;
    font-weight: 500;
    src: local(''),
         url('/fonts/barlow-semi-condensed-v6-latin-500.woff2') format('woff2'),
         url('/fonts/barlow-semi-condensed-v6-latin-500.woff') format('woff');
  }
  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-style: normal;
    font-weight: 600;
    src: local(''),
         url('/fonts/barlow-semi-condensed-v6-latin-600.woff2') format('woff2'),
         url('/fonts/barlow-semi-condensed-v6-latin-600.woff') format('woff');
  }
  @font-face {
    font-family: 'Barlow Semi Condensed';
    font-style: normal;
    font-weight: 700;
    src: local(''),
         url('/fonts/barlow-semi-condensed-v6-latin-700.woff2') format('woff2'),
         url('/fonts/barlow-semi-condensed-v6-latin-700.woff') format('woff');
  }

  body {
    font-family: "Barlow Semi Condensed", Helvetica,Arial,sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    width: auto !important;
    margin: 0;
    
    background-color: #16161a;
    color: #fffffe;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  form, .form-fields {
    display: flex;  
    flex-direction: inherit;
    width: inherit;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #16161a inset !important;
  }

  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #16161a inset !important;
  }
  
  input::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
    cursor: pointer;
  }
`;
