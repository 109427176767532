import * as React from 'react';

import { WithTwoFactorInterface } from '@components/User/TwoFactor/WithTwoFactorInterface';

interface TwoFactorState {
  otpChallenge: string;
  isValid: boolean;
}

interface TwoFactorProps {
  csrfToken: string;
  formAction: string;
  qrCodeSvg?: string;
}

export default function withTwoFactor<T extends WithTwoFactorInterface = WithTwoFactorInterface>(
  WrappedComponent: React.ComponentType<WithTwoFactorInterface>
): React.ComponentClass {
  return class TwoFactor extends React.Component<TwoFactorProps, TwoFactorState> {
    private formRef = React.createRef<HTMLFormElement>();

    constructor(props: TwoFactorProps) {
      super(props);

      this.state = {
        isValid: false,
        otpChallenge: ''
      };
    }

    handleOnChange = (event: React.SyntheticEvent<HTMLInputElement>, validateDigits = true): void => {
      const { value } = event.currentTarget;
      let { isValid } = this.state;

      if (value.length === 0 && value === '') {
        this.setState({ otpChallenge: value });
        return;
      }

      if (validateDigits) {
        if (!/^\d+$/.test(value)) {
          return;
        }

        isValid = value.length === 6;
      }

      this.setState({
        isValid,
        otpChallenge: value
      });
    };

    handleOnSubmit = (event: React.SyntheticEvent, validateDigits = true): boolean => {
      event.preventDefault();

      const { otpChallenge } = this.state;

      if (validateDigits) {
        if (otpChallenge.length === 0 || otpChallenge.length < 6 || !/^\d+$/.test(otpChallenge)) {
          this.setState({ isValid: false });
          return false;
        }

        if (this.formRef.current && otpChallenge.length === 6) {
          this.formRef.current.submit();
        }
      } else if (this.formRef.current) {
        this.formRef.current.submit();
      }

      return true;
    };

    render(): React.ReactNode {
      const {
        isValid,
        otpChallenge
      } = this.state;

      const {
        csrfToken,
        formAction,
        qrCodeSvg
      } = this.props;

      return (
        <WrappedComponent
          csrfToken={csrfToken}
          formAction={formAction}
          isValid={isValid}
          otpChallenge={otpChallenge}
          onChange={this.handleOnChange}
          onSubmit={this.handleOnSubmit}
          qrCodeSvg={qrCodeSvg}
          ref={this.formRef}
        />
      );
    }
  };
}
