import * as React from 'react';

import PlaylistApi                from '@api/PlaylistApi';
import FlashContext               from '@contexts/FlashContext';
import ExportPlaylistsView        from '@components/ExportPlaylists/ExportPlaylistsView';
import ExportToProvider           from '@components/ExportPlaylists/ExportToProvider';
import { ExportTypes }            from '@components/ExportPlaylists/ExportTypes';
import { UserProvidersInterface } from '@components/User/UserInterface';
import { PlaylistInterface }      from '@components/Playlist/PlaylistInterface';

interface ExportPlaylistsProps {
  editMode?: boolean;
  hasOrphanPlaylists: boolean;
  selectedIds?: Array<PlaylistInterface['id']>;
  userProviders: Array<UserProvidersInterface>;
}

interface ExportPlaylistsState {
  showExportToProviderDialog: boolean;
}

export default class ExportPlaylists extends React.Component<ExportPlaylistsProps, ExportPlaylistsState> {
  static contextType = FlashContext;
  static defaultProps = {
    editMode: false,
    selectedIds: []
  };

  constructor(props: ExportPlaylistsProps) {
    super(props);

    this.state = {
      showExportToProviderDialog: false
    };
  }

  handleOnClickExportToProvider = (): void => {
    this.setState({ showExportToProviderDialog: true });
  }

  handleOnCloseExportDialog = (): void => {
    this.setState({ showExportToProviderDialog: false });
  }

  handleOnClickExportFile = (exportType: ExportTypes): void => {
    const { selectedIds } = this.props;
    let exportFormat = '';

    if (exportType === ExportTypes.Csv) {
      exportFormat = 'csv';
    } else if (exportType === ExportTypes.Json) {
      exportFormat = 'json';
    }

    const joinedPlaylistIds = selectedIds.join(',');

    PlaylistApi.requestExport(joinedPlaylistIds, 'file', exportFormat, '', '', this.context);
  }

  render(): JSX.Element {
    const {
      showExportToProviderDialog
    } = this.state;

    const {
      editMode,
      hasOrphanPlaylists,
      selectedIds,
      userProviders
    } = this.props;

    const showFromProviderSelection = editMode ? false : (hasOrphanPlaylists || userProviders.length > 1);

    return (
      <>
        <ExportPlaylistsView
          editMode={editMode}
          onClickExportFile={this.handleOnClickExportFile}
          onClickExportToProvider={this.handleOnClickExportToProvider}
        />
        {showExportToProviderDialog && (
          <ExportToProvider
            hasOrphanPlaylists={hasOrphanPlaylists}
            showFromSelection={showFromProviderSelection}
            playlistIds={selectedIds}
            onCloseExportDialog={this.handleOnCloseExportDialog}
          />
        )}
      </>
    );
  }
}
