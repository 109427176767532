import * as React from 'react';

import TablePagination from '@material-ui/core/TablePagination';

interface PlaylistTrackPaginationProps {
  currentPage: number;
  totalRecords: number;
  onPageChange: (page: number, perPage: number) => void;
}

interface PlaylistTrackPaginationState {
  page: number;
  rowsPerPage: number;
}

export default class PlaylistTrackPagination extends React.PureComponent<
  PlaylistTrackPaginationProps,
  PlaylistTrackPaginationState
> {
  constructor(props: PlaylistTrackPaginationProps) {
    super(props);

    this.state = {
      page: props.currentPage,
      rowsPerPage: 25
    };
  }

  handleChangePage = (event, page: number) => {
    const { onPageChange }  = this.props;
    const { rowsPerPage }   = this.state;

    this.setState({ page }, () => {
      onPageChange(page, rowsPerPage);
    });
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);

    this.setState({ rowsPerPage }, () => {
      this.handleChangePage(event, 0);
    });
  };

  render(): React.ReactNode {
    const {
      page,
      rowsPerPage
    } = this.state;

    const { totalRecords } = this.props;

    return totalRecords > 0 ? (
      <TablePagination
        component="div"
        count={totalRecords}
        page={page}
        onPageChange={this.handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={this.handleChangeRowsPerPage}
      />
    ) : null;
  }
}
