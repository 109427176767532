import { PlaylistInterface } from '@components/Playlist/PlaylistInterface';

// @ts-ignore
import DefaultPlaylistThumb from 'images/default_playlist_thumb.svg';

interface ParsedPlaylist {
  playlistImage: string;
  title: string;
  subHeader: string;
  playlistLink: string;
  providerName: string;
}

export default class PlaylistHelper {
  playlist = null;

  constructor(playlist: PlaylistInterface) {
    this.playlist = playlist;
  }

  parseDisplayInfo(titleMaxChars = 30): ParsedPlaylist {
    const { playlist } = this;

    const provider = playlist.providerSource;

    return {
      playlistImage: playlist.images.length > 0 ? playlist.images[0].url : DefaultPlaylistThumb,
      title: `${playlist.name.slice(0, titleMaxChars)}${playlist.name.length > titleMaxChars ? '...' : ''}`,
      subHeader: `${playlist.trackCount} tracks`,
      playlistLink: `/playlists/${playlist.id}`,
      providerName: `${provider.slice(0, 1).toUpperCase()}${provider.slice(1, provider.length)}`
    };
  }
}
