import * as React from 'react';

import Button             from '@material-ui/core/Button';
import Dialog             from '@material-ui/core/Dialog';
import DialogActions      from '@material-ui/core/DialogActions';
import DialogContent      from '@material-ui/core/DialogContent';
import DialogContentText  from '@material-ui/core/DialogContentText';
import DialogTitle        from '@material-ui/core/DialogTitle';
import useMediaQuery      from '@material-ui/core/useMediaQuery';
import { useTheme }       from '@material-ui/core/styles';

interface ConfirmationDialogProps {
  cancelLabel?: string;
  confirmLabel?: string;
  content: JSX.Element;
  isOpen?: boolean;
  title: string;
  onClose?: () => void;
  onConfirm: () => void;
}

export default function ConfirmationDialog({
  cancelLabel,
  confirmLabel,
  content,
  isOpen,
  title,
  onClose,
  onConfirm
}: ConfirmationDialogProps): JSX.Element {
  const [open, setOpen] = React.useState(isOpen);
  const theme           = useTheme();
  const fullScreen      = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} variant="text" color="secondary">
          {cancelLabel}
        </Button>
        <Button onClick={handleConfirm} variant="contained" color="secondary" autoFocus>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.defaultProps = {
  cancelLabel: 'Cancel',
  confirmLabel: 'Proceed',
  isOpen: false,
  onClose: () => {}
};
