import * as React from 'react';

import Button from '@material-ui/core/Button';

import { AmazonMusicProviderInterface } from './AmazonMusicProviderInterface';

interface ConnectAmazonMusicProps {
  amazonMusicProvider: AmazonMusicProviderInterface;
  ssoState: string;
}

export default function ConnectAmazonMusic(props: ConnectAmazonMusicProps): JSX.Element {
  const {
    amazonMusicProvider,
    ssoState
  } = props;

  const {
    authUri,
    clientId,
    redirectUri,
    scopes
  } = amazonMusicProvider;

  /**
   * https://www.amazon.com/ap/oa
   * ?client_id=foodev
   * &scope=profile
   * &response_type=code
   * &state=208257577ll0975l93l2l59l895857093449424
   * &redirect_uri=https://client.example.com/auth_popup/token
   * &code_challenge=Fw7s3XHRVb2m1nT7s646UrYiYLMJ54as0ZIU_injyqw
   * &code_challenge_method=S256
   */
  const amazonMusicAuthUrl = `${authUri}?`
    + `client_id=${clientId}`
    + `&scope=${scopes}`
    + '&response_type=code'
    + `&state=${ssoState}`
    + `&redirect_uri=${redirectUri}`
  ;

  return (
    <Button
      href={amazonMusicAuthUrl}
      color="primary"
      variant="contained"
      fullWidth
    >
      Connect to Amazon Music
    </Button>
  );
}
