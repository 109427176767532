import * as React from 'react';
import Markdown from 'markdown-to-jsx';
import Grid from '@material-ui/core/Grid';

import markdownConfig    from '@resources/markdownConfig';
import HelpPageMarkdown from '@docs/help.md';

export default function Help(): JSX.Element {
  return (
    <Grid item xs={12}>
      <Markdown options={markdownConfig}>
        {HelpPageMarkdown}
      </Markdown>
    </Grid>
  );
}
