import request from 'superagent';

import BaseApi from '@api/BaseApi';

const ENDPOINT = '/users';

export default class AuthApi {
  static logout(): Promise<number> {
    const csrfToken = BaseApi.csrfToken();

    return request
      .delete(`${ENDPOINT}/sign_out`)
      .send({
        // eslint-disable-next-line @typescript-eslint/camelcase
        authenticity_token: csrfToken
      })
    ;
  }
}
