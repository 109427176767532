import * as React from 'react';

import Hidden   from '@material-ui/core/Hidden';
import Link     from '@material-ui/core/Link';
import List     from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import { MenuItemInterface } from '@components/Header/MenuItems/MenuItemInterface';

import NavButton  from '@styles/NavButton';
import Text       from '@styles/Text';

interface PublicUserMenuItemsProps {
  activeLink: string;
}

const items: Array<MenuItemInterface> = [
  {
    key: 'signup',
    label: 'Sign up',
    path: '/users/sign_up'
  },
  {
    key: 'login',
    label: 'Log in',
    path: '/users/login'
  }
];

export default function PublicUserMenuItems(props: PublicUserMenuItemsProps): JSX.Element {
  const {
    activeLink
  } = props;

  return (
    <>
      <Hidden xsDown>
        {items.map((item: MenuItemInterface) => (
          <NavButton key={item.key}>
            <Link
              href={item.path}
            >
              <Text type="Label" weight={500} color="white">
                {item.label}
              </Text>
            </Link>
          </NavButton>
        ))}
      </Hidden>
      <Hidden smUp>
        <List>
          {items.map((item: MenuItemInterface) => (
            <ListItem
              button
              component="a"
              key={item.key}
              selected={activeLink === item.path}
              href={item.path}
            >
              <Text color="white" type="label" weight={400}>
                {item.label}
              </Text>
            </ListItem>
          ))}
        </List>
      </Hidden>
    </>
  );
}
