import * as React from 'react';

import Button       from '@material-ui/core/Button';
import Grid         from '@material-ui/core/Grid';
import FormControl  from '@material-ui/core/FormControl';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import PasswordInput    from '@components/common/form/PasswordInput';
import withUserAccount  from '@components/User/withUserAccount';
import LoadingIndicator from '@components/common/LoadingIndicator';

import ContentActionDialog  from '@styles/dialogs/ContentActionDialog';
import Text                 from '@styles/Text';
import DefaultTheme         from '@themes/DefaultTheme';

import { WithUserAccountInterface } from '@components/User/WithUserAccountInterface';

const useStyles = makeStyles(({ colors }: DefaultTheme) => createStyles({
  formControl: {
    width: '100%'
  },
  submitBtn: {
    color: colors.red,
  },
  deleteBtn: {
    color: colors.red
  }
}));

const DeleteAccount = React.forwardRef((props: WithUserAccountInterface, ref: React.Ref<HTMLFormElement>): JSX.Element => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const {
    csrfToken,
    errors,
    formAction,
    onChange,
    onSave,
    user,
    saving
  } = props;

  const toggleDialogOpen = (): void => {
    setIsDialogOpen(!isDialogOpen);
  };

  const dialogTitle = (
    <>
      Delete my account
    </>
  );

  const dialogContent = (
    <form
      action={formAction}
      method="post"
      onSubmit={onSave}
      ref={ref}
      noValidate
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Text type="label">
            Are you sure? Your account and any imported playlist(s) will be removed.
          </Text>
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.formControl}>
            <PasswordInput
              fieldId="current_password"
              fieldName="user[current_password]"
              fieldLabel="Enter Password (Required)"
              fieldValue={user.current_password}
              onChangeHandler={onChange}
              error={errors.currentPassword}
              required
            />

            <input type="hidden" name="authenticity_token" value={csrfToken} />
            <input type="hidden" name="_method" value="delete" />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Button
            fullWidth
            className={classes.submitBtn}
            disabled={saving}
            onClick={onSave}
            type="submit"
          >
            {saving && <LoadingIndicator />}
            Delete account
          </Button>
        </Grid>
      </Grid>
    </form>
  );

  const dialogAction = null;

  return (
    <>
      <Button
        className={classes.deleteBtn}
        onClick={toggleDialogOpen}
        size="large"
        variant="text"
      >
        Delete my account
      </Button>

      <ContentActionDialog
        onClose={toggleDialogOpen}
        open={isDialogOpen}
        title={dialogTitle}
        content={dialogContent}
        action={dialogAction}
      />
    </>
  );
});

export default withUserAccount(DeleteAccount);
