import * as React from 'react';

import ToggleButton       from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup  from '@material-ui/lab/ToggleButtonGroup';
import ViewListIcon       from '@material-ui/icons/ViewList';
import ViewModuleIcon     from '@material-ui/icons/ViewModule';

interface ToggleViewProps {
  viewMode: string;
  onViewModeChange: (viewMode: string) => void;
}

export default function ToggleView({ viewMode, onViewModeChange }: ToggleViewProps): JSX.Element {
  const handleOnToggleChange = (event, nextview) => {
    onViewModeChange(nextview);
  };

  return (
    <ToggleButtonGroup
      value={viewMode}
      exclusive
      onChange={handleOnToggleChange}
      aria-label="text alignment"
      size="small"
    >
      <ToggleButton value="cards" aria-label="left aligned">
        <ViewModuleIcon />
      </ToggleButton>
      <ToggleButton value="list" aria-label="centered">
        <ViewListIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
