import * as React from 'react';

import Box  from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {
  createStyles,
  makeStyles
} from '@material-ui/styles';

import DefaultTheme from '@themes/DefaultTheme';
import Text         from '@styles/Text';

interface ErrorPageInterface {
  errorCode: number
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  contentBox: {
    marginTop: spacing(5)
  }
}));

export default function ErrorPage({ errorCode }: ErrorPageInterface): JSX.Element {
  const classes = useStyles();

  let title = (
    <Text type="heading">
      404 - Page not found
    </Text>
  );

  let content = (
    <Text type="displayXs">
      The page you are looking for could not be found.
    </Text>
  );

  if (errorCode === 422) {
    title = (
      <Text type="heading">
        Unprocessable Entity
      </Text>
    );

    content = (
      <Text type="displayXs">
        Hmm it looks like something went wrong.
      </Text>
    );
  }

  if (errorCode === 500) {
    title = (
      <Text type="heading">
        An internal error occurred
      </Text>
    );

    content = (
      <Text type="displayXs">
        The administrator has been notified. If you are experiencing issues please
        {' '}
        <a href="/contact">send a message</a>
        .
      </Text>
    );
  }

  return (
    <Grid item xs={12}>
      {title}

      <Box className={classes.contentBox}>
        {content}
      </Box>
    </Grid>
  );
}
