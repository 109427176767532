import consumer from '@channels/consumer';

import { NotificationCollectionInterface } from '@components/Notifications/NotificationCollectionInterface';

export default class NotificationsChannel {
  private subscription: null;

  constructor() {
    this.subscription = null;
  }

  subscribe(onReceivedCallback: (notificationCollection: NotificationCollectionInterface) => void): void {
    this.subscription = consumer.subscriptions.create({
      channel: 'NotificationsChannel'
    }, {
      received(notifications: NotificationCollectionInterface) {
        onReceivedCallback(notifications);
      }
    });
  }

  unsubscribe(): void {
    if (this.subscription) {
      consumer.subscriptions.remove(this.subscription);
    }
  }

  acknowledge(notificationId: string): void {
    if (this.subscription) {
      this.subscription.perform('ack', { id: notificationId });
    }
  }

  acknowledgeAll(): void {
    if (this.subscription) {
      this.subscription.perform('ack_all', {});
    }
  }
}
