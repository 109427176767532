import * as React from 'react';

import PlaylistApi          from '@api/PlaylistApi';
import ExportToProviderView from '@components/ExportPlaylists/ExportToProviderView';
import FlashContext         from '@contexts/FlashContext';

import { PlaylistInterface } from '@components/Playlist/PlaylistInterface';

interface ExportToProviderProps {
  onCloseExportDialog: () => void;
  playlistIds?: Array<PlaylistInterface['id']>;
  fromSourceInitialValue?: string;
  hasOrphanPlaylists?: boolean;
  showFromSelection?: boolean;
}

interface ExportToProviderState {
  dialogOpen: boolean;
}

export default class ExportToProvider extends React.Component<ExportToProviderProps, ExportToProviderState> {
  static contextType  = FlashContext;
  static defaultProps = {
    fromSourceInitialValue: '',
    hasOrphanPlaylists: false,
    playlistIds: [],
    showFromSelection: false
  };

  constructor(props: ExportToProviderProps) {
    super(props);

    this.state = {
      dialogOpen: true
    };
  }

  handleOnSubmitDialog = (fromSource: string = '', toSource: string = ''): void => {
    const {
      onCloseExportDialog,
      playlistIds
    } = this.props;

    const joinedPlaylistIds = playlistIds.join(',');

    PlaylistApi.requestExport(joinedPlaylistIds, 'provider', '', fromSource, toSource, this.context);
    onCloseExportDialog();
  }

  render(): JSX.Element {
    const {
      dialogOpen
    } = this.state;

    const {
      fromSourceInitialValue,
      hasOrphanPlaylists,
      onCloseExportDialog,
      playlistIds,
      showFromSelection
    } = this.props;

    return (
      <ExportToProviderView
        fromSourceInitialValue={fromSourceInitialValue}
        hasOrphanPlaylists={hasOrphanPlaylists}
        onCloseExportDialog={onCloseExportDialog}
        onSubmitExportDialog={this.handleOnSubmitDialog}
        open={dialogOpen}
        playlistIds={playlistIds}
        showFromSelection={showFromSelection}
      />
    );
  }
}
