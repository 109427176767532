import request from 'superagent';

import {
  BASE_URL,
  genericApiResponse
} from '@api/BaseApi';

const ENDPOINT = `${BASE_URL}/providers`;

export default class ProviderApi {
  static syncProviders(
    source?: string
  ): Promise<{ body: genericApiResponse }> {
    return request
      .post(`${ENDPOINT}/sync`)
      .send({ source })
    ;
  }

  static disconnectProvider(
    source: string
  ): Promise<{ body: genericApiResponse }> {
    return request
      .post(`${ENDPOINT}/disconnect`)
      .send({ source })
    ;
  }

  static invalidateProvider(
    source: string
  ): Promise<{ body: genericApiResponse }> {
    return request
      .post(`${ENDPOINT}/invalidate`)
      .send({ source })
    ;
  }
}
