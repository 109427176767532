import * as React from 'react';

import Button from '@material-ui/core/Button';

import OauthApi     from '@api/OauthApi';
import ProviderApi  from '@api/ProviderApi';
import FlashContext from '@contexts/FlashContext';

import { defaultApiErrorMessage } from '@api/BaseApi';

import { AppleMusicProviderInterface } from '@components/Providers/AppleMusic/AppleMusicProviderInterface';

interface ConnectAppleMusicProps {
  appleMusicProvider: AppleMusicProviderInterface;
}

export default class ConnectAppleMusic extends React.PureComponent<ConnectAppleMusicProps> {
  static contextType = FlashContext;
  private readonly musicKit: any;

  constructor(props: ConnectAppleMusicProps) {
    super(props);

    const {
      appleMusicProvider
    } = props;

    let musicKitInstance = null;

    try {
      musicKitInstance = MusicKit.configure({
        developerToken: appleMusicProvider.token,
        app: {
          name: 'Playlists.cloud',
          version: '2021.01'
        }
      });
    } catch (e) {
      // @todo report to sentry
      console.log('Failed to connect to Apple Music.', e);

      ProviderApi.invalidateProvider('apple_music')
        .then(() => {})
        .catch(() => {})
      ;
    }

    this.musicKit = musicKitInstance;
  }

  oauthCallback = (musicUserToken: string, storefrontId: string): void => {
    OauthApi.authAppleMusicUser(musicUserToken, storefrontId)
      .then((res) => {
        const status = ['success', res.body.message];
        this.context.flashMessage(status);

        setTimeout(() => { window.location.reload(); }, 1000);
      })
      .catch((res) => {
        const message = res.response ? res.response.body.error : defaultApiErrorMessage;
        const error = ['alert', message];
        this.context.flashMessage(error);
      });
  }

  authorizeUser = (e) => {
    if (this.musicKit === null) {
      const error = ['alert', 'Unable to connect to Apple Music. We have been notified. Please try again later.'];
      this.context.flashMessage(error);
      return;
    }

    this.musicKit
      .authorize()
      .then((res: string) => {
        this.oauthCallback(res, this.musicKit.storefrontId);
      }).catch((err) => {
        const error = ['alert', err.response.body.message];
        this.context.flashMessage(error);
      })
    ;
  }

  render(): React.ReactNode {
    return (
      <Button
        onClick={this.authorizeUser}
        color="primary"
        variant="contained"
        fullWidth
      >
        Connect to Apple Music
      </Button>
    );
  }
}
