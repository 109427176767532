import * as React from 'react';

import Box          from '@material-ui/core/Box';
import Breadcrumbs  from '@material-ui/core/Breadcrumbs';
import Button       from '@material-ui/core/Button';
import Grid         from '@material-ui/core/Grid';
import Link         from '@material-ui/core/Link';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import { UserInterface } from '@components/User/UserInterface';

import DefaultTheme from '@themes/DefaultTheme';
import PageGridItem from '@styles/PageGridItem';
import Text         from '@styles/Text';

interface TwoFactorHomeProps {
  user: UserInterface;
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  headingBox: {
    marginBottom: spacing(2)
  },
  bodyText: {
    marginBottom: spacing(4),
    marginTop: spacing(4)
  },
  paragraph: {
    marginBottom: spacing(2)
  }
}));

export default function TwoFactorHome({ user }: TwoFactorHomeProps): JSX.Element {
  const classes = useStyles();

  const pageTitle = 'Two-Factor Authentication';

  const onClickEnable2Fa = () => {
    window.location.href = '/account/2fa/enable';
  };

  const onClickDisable2Fa = () => {
    window.location.href = '/account/2fa/disable';
  };

  const text = user.twoFactorEnabled ? (
    <Box className={classes.bodyText}>
      <Text className={classes.paragraph} type="label">
        Two-factor authentication is enabled on your account.
      </Text>
    </Box>
  ) : (
    <Box className={classes.bodyText}>
      <Text className={classes.paragraph} type="label">
        Add an extra layer of protection to your account by enabling two-factor authentication.
      </Text>

      <Text className={classes.paragraph} type="label">
        You'll need an authenticator app. If you don't have one, there are several options available in your mobile app store, such as Google Authenticator or Authy.
      </Text>
    </Box>
  );

  const action = user.twoFactorEnabled ? (
    <>
      <Button
        color="primary"
        onClick={onClickDisable2Fa}
        type="submit"
        variant="contained"
        size="large"
      >
        Disable Two-Factor Authentication
      </Button>
    </>
  ) : (
    <Button
      color="primary"
      onClick={onClickEnable2Fa}
      type="submit"
      variant="contained"
      size="large"
    >
      Enable Two-Factor Authentication
    </Button>
  );

  return (
    <PageGridItem>
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/account">
                <Text type="label">
                  My Account
                </Text>
              </Link>

              <Text type="label" color="secondary">
                {pageTitle}
              </Text>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.headingBox}>
              <Text type="heading">
                {pageTitle}
              </Text>
            </Box>
            {text}
          </Grid>
          <Grid item xs={12}>
            {action}
          </Grid>
        </Grid>
      </>
    </PageGridItem>
  );
}
