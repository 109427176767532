import * as React from 'react';

import Box            from '@material-ui/core/Box';
import Card           from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia      from '@material-ui/core/CardMedia';
import CardContent    from '@material-ui/core/CardContent';
import Checkbox       from '@material-ui/core/Checkbox';
import IconButton     from '@material-ui/core/IconButton';
import MoreVertIcon   from '@material-ui/icons/MoreVert';
import {
  createStyles,
  makeStyles
} from '@material-ui/styles';

import { PlaylistInterface }  from '@components/Playlist/PlaylistInterface';
import PlaylistHelper         from '@resources/helpers/PlaylistHelper';
import Text                   from '@themes/styles/Text';
import DefaultTheme           from '@themes/DefaultTheme';
import PlaylistMenu           from '@components/Playlist/PlaylistMenu/PlaylistMenu';

interface PlaylistListItemProps {
  inEditMode: boolean;
  isSelected: boolean;
  onEditSelectItem: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  playlist: PlaylistInterface
}

const useStyles = makeStyles(({ breakpoints, spacing }: DefaultTheme) => createStyles({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative'
  },
  content: {
    flex: '1 0 auto',
    '& a': {
      textDecoration: 'none'
    }
  },
  marginTop: {
    marginTop: spacing(3)
  },
  menuBox: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  cover: {
    [breakpoints.down('sm')]: {
      width: 210,
      minHeight: 60
    },
    [breakpoints.up('sm')]: {
      width: 390,
      minHeight: 220
    }
  }
}));

export default function PlaylistListItem({
  inEditMode,
  isSelected,
  onEditSelectItem,
  playlist
}: PlaylistListItemProps): JSX.Element {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const classes         = useStyles();
  const playlistHelper  = new PlaylistHelper(playlist);

  const handleMenuClick = (event) => setMenuAnchorEl(event.currentTarget);
  const handleMenuClose = () => setMenuAnchorEl(null);

  const {
    title,
    subHeader,
    playlistLink,
    playlistImage,
    providerName
  } = playlistHelper.parseDisplayInfo(55);

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.cover}
        image={playlistImage}
        title={title}
      />
      <div className={classes.details}>
        <CardActionArea className={classes.content}>
          <a href={playlistLink}>
            <CardContent>
              <Box>
                <Text type="displayMd">
                  {title}
                </Text>
                <Text type="label">
                  {subHeader}
                </Text>
              </Box>

              <Box className={classes.marginTop}>
                <Text type="regular">
                  {`Last updated ${playlist.lastSyncedAt}. `}
                  {`Created ${playlist.createdAt}.`}
                </Text>
                <Text type="regular">
                  {`Imported from ${providerName}.`}
                </Text>
              </Box>

              <Box className={classes.marginTop}>
                <Text type="label">
                  {playlist.description}
                </Text>
              </Box>
            </CardContent>
          </a>
        </CardActionArea>

        <Box className={classes.menuBox}>
          <IconButton aria-label="settings" onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>

          {inEditMode && (
            <Checkbox
              checked={isSelected}
              onChange={onEditSelectItem}
              value={playlist.id}
            />
          )}
        </Box>
      </div>

      <PlaylistMenu
        anchorEl={menuAnchorEl}
        onClose={handleMenuClose}
        playlist={playlist}
        variant="Menu"
      />
    </Card>
  );
}
