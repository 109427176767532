import * as React from 'react';

import Alert    from '@material-ui/lab/Alert';
import Slide    from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';

interface BottomLeftSnackbarProps {
  onClose: (event, reason) => void;
  open: boolean;
  severity: string;
  msg: string;
}

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

export default function BottomLeftSnackbar({
  onClose,
  open,
  severity,
  msg
}: BottomLeftSnackbarProps): JSX.Element {
  return (
    <Snackbar
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={onClose}
      open={open}
      TransitionComponent={SlideTransition}
    >
      <Alert severity={severity}>
        {msg}
      </Alert>
    </Snackbar>
  );
}
