import * as React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Box    from '@material-ui/core/Box';
import Grid   from '@material-ui/core/Grid';

import { createStyles, makeStyles } from '@material-ui/styles';

import Text from '@styles/Text';
import DefaultTheme, { colorHighlights } from '@themes/DefaultTheme';

interface GreetingProps {
  color?: string;
  username: string;
}

const useStyles = makeStyles(({ breakpoints, colors }: DefaultTheme) => createStyles({
  avatar: {
    [breakpoints.down('xs')]: {
      width: '25px',
      height: '25px',
      minHeight: '25px'
    },

    [breakpoints.up('sm')]: {
      width: '40px',
      height: '40px',
      minHeight: '40px'
    },

    backgroundColor: (props: Partial<GreetingProps>): string => colors[props.color]
  },
  text: {
    marginLeft: '10px',

    [breakpoints.up('sm')]: {
      marginTop: '6px',
      marginRight: '2px',
      '& p': {
        opacity: 0.7
      }
    }
  }
}));

export default function Greeting({ username }: GreetingProps): JSX.Element {
  const colors  = Object.keys(colorHighlights);
  const color   = colors[username.length % colors.length];
  const letter  = username.substr(0, 1).toUpperCase();

  const classes = useStyles({ color });

  return (
    <Grid container>
      <Grid item>
        <Avatar className={classes.avatar}>
          <Text type="Label" weight={500} color="white">
            {letter}
          </Text>
        </Avatar>
      </Grid>
      <Grid item>
        <Box className={classes.text}>
          <Text type="label" color="white" component="span">
            {`Hello, ${username}`}
          </Text>
        </Box>
      </Grid>
    </Grid>
  );
}
