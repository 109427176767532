import * as React     from 'react';
import ReactRailsUJS  from 'react_ujs';

import Container          from '@material-ui/core/Container';
import Grid               from '@material-ui/core/Grid';
import { ThemeProvider }  from '@material-ui/core/styles';

import HeaderBar            from '@components/Header/HeaderBar';
import FooterBar            from '@components/Footer/FooterBar';
import FlashMessages        from '@components/common/Flash/FlashMessages';
import NotificationFlashes  from '@components/Notifications/NotificationFlashes';

import { AppStatsInterface }               from '@components/common/AppStatsInterface';
import { UserInterface }                   from '@components/User/UserInterface';
import { NotificationCollectionInterface } from '@components/Notifications/NotificationCollectionInterface';

import FlashContext from '@contexts/FlashContext';
import UserContext  from '@contexts/UserContext';
import DefaultTheme from '@themes/DefaultTheme';
import GlobalStyles from '@themes/GlobalStyles';

interface PageState {
  displayFlashes: boolean;
  dismissFlashMessage: () => void;
  flashMessage: (flash: Array<string>) => void;
  flashes?: Array<Array<string>>;
}

interface PageProps {
  appStats?: AppStatsInterface;
  child: string;
  flashes?: Array<Array<string>>;
  user?: UserInterface;
  notificationCollection?: NotificationCollectionInterface;
}

export default class Page extends React.Component<PageProps, PageState> {
  static defaultProps = {
    flashes: [],
    notificationCollection: [],
    user: {}
  };

  constructor(props: PageProps) {
    super(props);

    this.state = {
      displayFlashes: props.flashes.length > 0,
      /* eslint-disable react/no-unused-state */
      dismissFlashMessage: this.dismissFlashMessage,
      flashMessage: this.flashMessage,
      flashes: props.flashes
      /* eslint-enable react/no-unused-state */
    };
  }

  flashMessage = (flash: Array<string>): void => {
    const flashes = [flash];
    // eslint-disable-next-line react/no-unused-state
    this.setState({ flashes, displayFlashes: true });
  }

  dismissFlashMessage = (): void => {
    this.setState({ displayFlashes: false });
  }

  render(): React.ReactNode {
    const {
      displayFlashes
    } = this.state;

    const {
      appStats,
      child,
      notificationCollection,
      user,
      ...rest
    } = this.props;

    const Component = ReactRailsUJS.getConstructor(child);

    return (
      <>
        <GlobalStyles />
        <ThemeProvider theme={DefaultTheme}>
          <HeaderBar
            appStats={appStats}
            user={user}
            notificationCollection={notificationCollection}
          />

          <Container fixed style={{ padding: '0 20px' }}>
            <Grid container spacing={5}>
              <UserContext.Provider value={user}>
                <FlashContext.Provider value={this.state}>
                  <FlashMessages open={displayFlashes} />
                  <Component user={user} {...rest} />
                  <NotificationFlashes />
                </FlashContext.Provider>
              </UserContext.Provider>
            </Grid>

            <FooterBar />
          </Container>

        </ThemeProvider>
      </>
    );
  }
}
