import * as React from 'react';

import Grid     from '@material-ui/core/Grid';
import AppBar   from '@material-ui/core/AppBar';
import Toolbar  from '@material-ui/core/Toolbar';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import Logo         from '@components/Header/Logo';
import Menu         from '@components/Header/Menu';
import DefaultTheme from '@themes/DefaultTheme';

import { AppStatsInterface }               from '@components/common/AppStatsInterface';
import { UserInterface }                   from '@components/User/UserInterface';
import { NotificationCollectionInterface } from '@components/Notifications/NotificationCollectionInterface';

const useStyles = makeStyles(({ breakpoints, colors }: DefaultTheme) => createStyles({
  root: {
    flexGrow: 1,
    marginBottom: '80px',
    [breakpoints.up('sm')]: {
      marginBottom: '100px',
    }
  },
  appBar: {
    backgroundColor: colors.black
  },
  logoGridItem: {
    [breakpoints.down('xs')]: {
      marginTop: '5px'
    }
  }
}));

interface HeaderBarProps {
  appStats?: AppStatsInterface;
  notificationCollection: NotificationCollectionInterface;
  user?: UserInterface;
}

export default function HeaderBar(props: HeaderBarProps): JSX.Element {
  const {
    appStats,
    notificationCollection,
    user
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar
        className={classes.appBar}
        elevation={5}
        position="fixed"
      >
        <Toolbar>
          <Grid container spacing={0} justifyContent="space-between">
            <Grid item className={classes.logoGridItem}>
              <Logo />
            </Grid>
            <Grid item>
              <Menu
                location="home"
                appStats={appStats}
                notificationCollection={notificationCollection}
                user={user}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}
