import * as React from 'react';

import IconButton     from '@material-ui/core/IconButton';
import TextField      from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon     from '@material-ui/icons/Search';

interface SearchInputProps {
  className?: string;
  highlightError?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onSubmit: (event: React.SyntheticEvent) => void,
  placeholder?: string;
  term: string;
}

export default function SearchInput({
  className = '',
  highlightError = false,
  onChange,
  onSubmit,
  placeholder = '',
  term
}: SearchInputProps): JSX.Element {
  return (
    <TextField
      type="search"
      className={className}
      onChange={onChange}
      error={highlightError}
      placeholder={placeholder}
      size="small"
      value={term}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="trigger search"
              edge="end"
              onClick={onSubmit}
              onMouseDown={onSubmit}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
}
