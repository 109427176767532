import * as React from 'react';
import request from 'superagent';

import { FlashContextInterface }  from '@contexts/FlashContext';
import { PlaylistTrackInterface } from '@components/Playlist/PlaylistTrackInterface';
import { PlaylistInterface }      from '@components/Playlist/PlaylistInterface';
import {
  BASE_URL,
  defaultApiErrorMessage,
  genericApiResponse
} from '@api/BaseApi';

const ENDPOINT = `${BASE_URL}/playlists`;

interface getPlaylistsResponse {
  page: number;
  pages: number;
  playlists: Array<PlaylistInterface>;
  total: number;
}

interface getPlaylistTracksResponse {
  page: number;
  playlistTracks: Array<PlaylistTrackInterface>;
  total: number;
}

export default class PlaylistApi {
  static getPlaylists(
    order:      string = 'ASC',
    page:       number = 1,
    searchTerm: string = '',
    sortBy:     string = null,
    sources:    Array<string> = [],
  ): Promise<{ body: getPlaylistsResponse }> {
    const sortOrderBy       = sortBy ? `&sort_by=${sortBy}&order=${order}` : '';
    const filteredBySources = sources.length > 0 ? `&sources=${sources.join(',')}` : '';
    const term              = searchTerm.length > 0 ? `&term=${searchTerm}` : '';

    return request
      .get(`${ENDPOINT}/?page=${page}${sortOrderBy}${filteredBySources}${term}`)
    ;
  }

  static getPlaylistTracks(
    id:         string,
    page:       number = 0,
    perPage:    number = 0,
    searchTerm: string = '',
    sortBy:     string = null,
    order:      string = 'ASC'
  ): Promise<{ body: getPlaylistTracksResponse }> {
    const itemsPerPage  = perPage > 0 ? `&per_page=${perPage}` : '';
    const sortOrderBy   = sortBy ? `&sort_by=${sortBy}&order=${order}` : '';
    const term          = searchTerm.length > 0 ? `&term=${searchTerm}` : '';

    return request
      .get(`${ENDPOINT}/${id}/tracks?page=${page}${itemsPerPage}${sortOrderBy}${term}`)
    ;
  }

  static syncPlaylist(id: string): Promise<{ body: genericApiResponse }> {
    return request
      .post(`${ENDPOINT}/${id}/sync`)
    ;
  }

  static exportPlaylists(
    ids: string,
    exportMode: string,
    exportFormat: string = '',
    fromSource: string = '',
    toSource: string = ''
  ): Promise<{ body: genericApiResponse }> {
    return request
      .post(`${ENDPOINT}/export`)
      .send({
        ids,
        export_mode: exportMode,
        export_format: exportFormat,
        from_source: fromSource,
        to_source: toSource
      })
    ;
  }

  static requestExport(
    ids: string,
    exportMode: string,
    exportFormat: string,
    fromSource: string,
    toSource: string,
    context: React.Context<FlashContextInterface>
  ): void {
    PlaylistApi
      .exportPlaylists(
        ids,
        exportMode,
        exportFormat,
        fromSource,
        toSource
      )
      .then((res: { body: genericApiResponse }) => {
        const status = ['notice', res.body.status];
        context.flashMessage(status);
      })
      .catch((res) => {
        const message = res.response ? res.response.body.error : defaultApiErrorMessage;
        const error = ['alert', message];
        context.flashMessage(error);
      })
    ;
  }

  static deletePlaylist(id: string): Promise<{ body: genericApiResponse }> {
    return request
      .del(`${ENDPOINT}/${id}`)
    ;
  }

  static deletePlaylists(
    playlistIds: Array<PlaylistInterface['id']>,
    searchTerm: string = '',
    sources: Array<string> = []
  ): Promise<{ body: genericApiResponse }> {
    const filteredBySources = sources.length > 0 ? `&sources=${sources.join(',')}` : '';
    const term              = searchTerm.length > 0 ? `&term=${searchTerm}` : '';

    return request
      .delete(`${ENDPOINT}?${filteredBySources}${term}`)
      .send({
        ids: playlistIds
      })
    ;
  }
}
