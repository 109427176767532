import styled from 'styled-components';

export default styled.div`
  color: inherit;
  cursor: pointer;
  margin: 5px 10px;
  display: inline-flex;
  padding-bottom: 1px;

  a:hover {
    border-bottom: 1px solid;
    color: inherit;
  }
`;
