import * as React from 'react';

import List         from '@material-ui/core/List';
import ListItem     from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import { UserStatsInterface } from '@components/Dashboard/UserStatsInterface';

import DefaultTheme from '@themes/DefaultTheme';
import Text         from '@styles/Text';

interface UserStatsProps {
  stats: UserStatsInterface;
}

interface StatsProps {
  children: React.ReactNode;
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  listItemIcon: {
    display: 'block',
    marginRight: spacing(2),
    textAlign: 'right'
  },
  listItemText: {
    textTransform: 'capitalize'
  }
}));

function StatsCount(props: StatsProps): JSX.Element {
  return (
    <Text
      type="displaySm"
      color="green"
      component="span"
      weight={600}
    >
      {props.children}
    </Text>
  );
}

function StatsLabel(props: StatsProps): JSX.Element {
  return (
    <Text type="displaySm" component="span">
      {props.children}
    </Text>
  );
}

export default function UserStats({ stats }: UserStatsProps): JSX.Element {
  const classes = useStyles();

  return (
    <>
      <Text type="displayMd">
        Stats
      </Text>

      <List dense>
        {Object.keys(stats).map((key: string): JSX.Element => (
          <ListItem key={key} disableGutters>
            <ListItemIcon className={classes.listItemIcon}>
              <StatsCount>
                {stats[key]}
              </StatsCount>
            </ListItemIcon>
            <ListItemText className={classes.listItemText}>
              <StatsLabel>
                {key}
              </StatsLabel>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </>
  );
}
