import * as React from 'react';

import Box from '@material-ui/core/Box';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

interface DesktopMenuViewProps {
  children: React.ReactNode;
}

const useStyles = makeStyles(() => createStyles({
  root: {
    '& a:hover': {
      textDecoration: 'none'
    }
  }
}));

export default function DesktopMenuView({ children }: DesktopMenuViewProps): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {children}
    </Box>
  );
}
