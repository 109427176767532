export default class LocalStorage {
  static set(key: string, value: string) {
    window.localStorage.setItem(key, value);
  }

  static get(key: string): string {
    return window.localStorage.getItem(key);
  }

  static remove(key: string) {
    window.localStorage.removeItem(key);
  }

  static clear() {
    window.localStorage.clear();
  }
}
