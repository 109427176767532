import styled         from 'styled-components';
import ArrowDropDown  from '@material-ui/icons/ArrowDropDown';

export default styled(ArrowDropDown)`
  && {
    font-size: 'default';
    color: #fff;
    transition: transform 0.3s;
    transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
    ${(props) => (props.margin ? `margin: ${props.margin};` : '')};
    opacity: ${(props) => (props.opacity ? props.opacity : 1)};
  }
`;
