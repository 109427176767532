import * as React     from 'react';
import Grid           from '@material-ui/core/Grid';
import Box            from '@material-ui/core/Box';
import Divider        from '@material-ui/core/Divider';
import FormControl    from '@material-ui/core/FormControl';
import Link           from '@material-ui/core/Link';
import TextField      from '@material-ui/core/TextField';
import Fade           from '@material-ui/core/Fade';
import Button         from '@material-ui/core/Button';
import useMediaQuery  from '@material-ui/core/useMediaQuery';
import {
  createStyles,
  makeStyles
} from '@material-ui/styles';

import DefaultTheme   from '@themes/DefaultTheme';
import Text           from '@styles/Text';
import PageGridItem   from '@styles/PageGridItem';
import FormHelperText from '@styles/form/FormHelperText';

type ContactMessageErrorType = {
  email?: string;
  message?: string;
}

export interface ContactMessageProps {
  csrfToken: string;
  email: string;
  message: string;
  errors: ContactMessageErrorType;
  formAction: string;
  ref: React.Ref<HTMLFormElement>;
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  marginTop: {
    marginTop: spacing(5)
  },
  headingBox: {
    marginBottom: spacing(2)
  },
  submitBtn: {
    marginTop: spacing(2)
  }
}));

export default function About(props: ContactMessageProps): JSX.Element {
  const classes = useStyles();
  const formRef = React.useRef(null);
  const isMobileResolution = useMediaQuery('(max-width:767px)');

  const [email, setEmail] = React.useState(props.email);
  const [message, setMessage] = React.useState(props.message);
  const errors = {
    email: '',
    message: '',
    ...props.errors
  };

  const {
    csrfToken,
    formAction
  } = props;

  const onSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();

    if (formRef.current) {
      formRef.current.submit();
    }
  };

  return (
    <Grid item xs={12}>
      <PageGridItem>
        <form
          action={formAction}
          className={classes.marginTop}
          method="post"
          onSubmit={onSubmit}
          ref={formRef}
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className={classes.headingBox}>
                <Text type="heading">
                  Contact
                </Text>
              </Box>

              <Text type="displayXs">
                Do you have feedback to share? Found an issue? Need help? Fill out the form below to send a message:
              </Text>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                error={errors.email.length > 0}
                fullWidth
              >
                <TextField
                  error={errors.email.length > 0}
                  name="contact[email]"
                  id="email"
                  label="Email"
                  type="email"
                  onChange={(event) => setEmail(event.currentTarget.value)}
                  value={email}
                  variant="outlined"
                  required
                />

                {errors.email.length > 0 && (
                  <Fade in>
                    <FormHelperText>
                      {errors.email}
                    </FormHelperText>
                  </Fade>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                error={errors.message.length > 0}
                fullWidth
              >
                <TextField
                  error={errors.message.length > 0}
                  name="contact[message]"
                  id="message"
                  label="Message"
                  type="text"
                  onChange={(event) => setMessage(event.currentTarget.value)}
                  value={message}
                  variant="outlined"
                  rows={5}
                  multiline
                  required
                />

                {errors.email.length > 0 && (
                  <Fade in>
                    <FormHelperText>
                      {errors.email}
                    </FormHelperText>
                  </Fade>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <input type="hidden" name="authenticity_token" value={csrfToken} />
              <Button
                className={classes.submitBtn}
                color="primary"
                fullWidth={isMobileResolution}
                onClick={onSubmit}
                type="submit"
                variant="contained"
                size="large"
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </form>

        <Divider className={classes.marginTop} />

        <Grid container spacing={2} className={classes.marginTop}>
          <Grid item>
            <Text type="displayXs">
              You can also join our Discord server to share your feedback, ask questions, report errors, and more:
              {` `}
              <Link
                href="https://discord.gg/H7Xg9AAHsq"
                target="_blank"
              >
                https://discord.gg/H7Xg9AAHsq
              </Link>
            </Text>
          </Grid>
        </Grid>
      </PageGridItem>
    </Grid>
  );
}
