import * as React from 'react';
import Markdown   from 'markdown-to-jsx';
import Grid       from '@material-ui/core/Grid';

import markdownConfig    from '@resources/markdownConfig';
import AboutPageMarkdown from '@docs/about.md';

export default function About(): JSX.Element {
  return (
    <Grid item xs={12}>
      <Markdown options={markdownConfig}>
        {AboutPageMarkdown}
      </Markdown>
    </Grid>
  );
}
