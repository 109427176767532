import styled       from 'styled-components';
import MuiCloseIcon from '@material-ui/icons/Close';

export default styled(MuiCloseIcon)`
  cursor: pointer;
  position: ${(props) => (props.position ? props.position : 'relative')};
  ${(props) => (props.left ? `left: ${props.left};` : '')}
  ${(props) => (props.right ? `right: ${props.right};` : '')}
  top: ${(props) => (props.top ? props.top : '20px')};
  color: ${(props) => (props.color ? props.color : 'primary')};
`;
