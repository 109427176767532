import * as React from 'react';

import HelpIcon       from '@material-ui/icons/Help';
import IconButton     from '@material-ui/core/IconButton';
import Popover        from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';

import Text from '@styles/Text';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing(1)
  }
}));

export default function OrphanTooltip(): JSX.Element {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        onClick={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        color="secondary"
        disableRipple
      >
        <HelpIcon fontSize="small" />
      </IconButton>
      <Popover
        id="orphaned-playlist-tooltip"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Text type="label">
          This playlist was imported from a provider that is no longer connected to your account.
        </Text>
      </Popover>
    </>
  );
}
