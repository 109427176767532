import * as React from 'react';

import Fade           from '@material-ui/core/Fade';
import FormControl    from '@material-ui/core/FormControl';
import IconButton     from '@material-ui/core/IconButton';
import OutlinedInput  from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel     from '@material-ui/core/InputLabel';
import Visibility     from '@material-ui/icons/Visibility';
import VisibilityOff  from '@material-ui/icons/VisibilityOff';

import FormHelperText from '@styles/form/FormHelperText';

interface PasswordInputState {
  displayPassword: boolean;
}

interface PasswordInputProps {
  displayTypeSwitch: boolean;
  fieldId: string;
  fieldName: string;
  fieldLabel: string;
  fieldValue: string;
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error: string;
  required?: boolean;
}

export default class PasswordInput extends React.PureComponent<PasswordInputProps, PasswordInputState> {
  static defaultProps = {
    displayTypeSwitch: false,
    required: false
  };

  constructor(props: PasswordInputProps) {
    super(props);

    this.state = {
      displayPassword: false
    };
  }

  handleClick = (): void => {
    this.setState((prevState: PasswordInputState): PasswordInputState => ({
      ...prevState,
      displayPassword: !prevState.displayPassword
    }));
  };

  render(): JSX.Element {
    const { displayPassword } = this.state;

    const {
      displayTypeSwitch,
      fieldId,
      fieldName,
      fieldLabel,
      fieldValue,
      onChangeHandler,
      error,
      required
    } = this.props;

    const passwordFieldType = displayPassword ? 'text' : 'password';
    const labelLength       = fieldLabel.length * 8.5;

    return (
      <FormControl fullWidth variant="outlined" error={error.length > 0}>
        <InputLabel htmlFor={fieldName}>{`${fieldLabel} *`}</InputLabel>
        <OutlinedInput
          required={required}
          id={fieldId}
          labelWidth={labelLength}
          name={fieldName}
          onChange={onChangeHandler}
          type={passwordFieldType}
          value={fieldValue}
          endAdornment={displayTypeSwitch && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={this.handleClick}
              >
                {displayPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )}
        />

        {error.length > 0 && (
          <Fade in>
            <FormHelperText variant="standard">
              {error}
            </FormHelperText>
          </Fade>
        )}
      </FormControl>
    );
  }
}
