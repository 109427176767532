import * as React from 'react';

import AmazonMusicLogo  from 'images/amazon_music_logo.png';
import AppleMusicIcon   from 'images/apple_music_icon_colored_lg.svg';
import DeezerLogo       from 'images/deezer_full_white_logo.png';
import SpotifyLogo      from 'images/spotify_logo.png';

interface ProviderLogoProps {
  providerSource: string;
  height?: number;
}

export default function ProviderLogo({ providerSource, height = 35 }: ProviderLogoProps): JSX.Element {
  const images = {
    amazonMusic:  <img src={AmazonMusicLogo} alt="Amazon Music" title="Amazon Music" height={height} />,
    appleMusic:   <img src={AppleMusicIcon} alt="Apple Music" title="Apple Music" height={height} />,
    deezer:       <img src={DeezerLogo} alt="Deezer" title="Deezer" height={height} />,
    spotify:      <img src={SpotifyLogo} alt="Spotify" title="Spotify" height={height} />
  };

  return images[providerSource];
}
