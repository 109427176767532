import * as React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

const useStyles = makeStyles(() => createStyles({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

export default function LoadingIndicator(): JSX.Element {
  const classes = useStyles();

  return (
    <CircularProgress size={24} className={classes.root} />
  );
}
