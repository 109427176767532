import * as React from 'react';

export interface FlashContextInterface {
  displayFlashes: boolean;
  dismissFlashMessage: () => void;
  flashMessage: (flash: Array<string>) => void;
  flashes: Array<Array<string>>;
}

export default React.createContext<FlashContextInterface>({
  displayFlashes: false,
  dismissFlashMessage: () => {},
  flashMessage: (flash: Array<string>) => {},
  flashes: []
});
