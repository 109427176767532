import * as React from 'react';

import Box            from '@material-ui/core/Box';
import Button         from '@material-ui/core/Button';
import Fade           from '@material-ui/core/Fade';
import FormControl    from '@material-ui/core/FormControl';
import Grid           from '@material-ui/core/Grid';
import TextField      from '@material-ui/core/TextField';
import useMediaQuery  from '@material-ui/core/useMediaQuery';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import FormHelperText     from '@styles/form/FormHelperText';
import DefaultTheme       from '@themes/DefaultTheme';
import PageGridItem       from '@styles/PageGridItem';
import Text               from '@styles/Text';

import {
  EMAIL_REGEX
} from '@resources/validationRules';

interface ForgotPasswordProps {
  csrfToken: string;
  formAction: string;
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  form: {
    marginTop: spacing(5)
  },
  headingBox: {
    marginBottom: spacing(2)
  },
  submitBtn: {
    marginTop: spacing(2)
  }
}));

export default function ForgotPassword({
  csrfToken,
  formAction
}: ForgotPasswordProps): JSX.Element {
  const classes = useStyles();
  const formRef = React.useRef(null);
  const isMobileResolution = useMediaQuery('(max-width:767px)');

  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState(null);

  const updateEmail = (e): void => {
    setEmail(e.currentTarget.value);
  };

  const onSubmit = (e): boolean => {
    e.preventDefault();

    if (!email || EMAIL_REGEX.test(email) === false) {
      setError('Invalid email provided.');
      return false;
    }

    setError(null);
    formRef.current.submit();
    return true;
  };

  const hasError = error && error.length > 0;

  return (
    <PageGridItem>
      <form
        action={formAction}
        className={classes.form}
        ref={formRef}
        method="post"
        onSubmit={onSubmit}
        noValidate
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className={classes.headingBox}>
              <Text type="heading">
                Forgot your password?
              </Text>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth error={hasError}>
              <TextField
                name="user[email]"
                error={hasError}
                id="email"
                label="Email Address"
                type="email"
                onChange={updateEmail}
                value={email}
                variant="outlined"
                required
              />

              {error && (
                <Fade in>
                  <FormHelperText>
                    {error}
                  </FormHelperText>
                </Fade>
              )}

              <input type="hidden" name="authenticity_token" value={csrfToken} />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Button
              className={classes.submitBtn}
              color="primary"
              fullWidth={isMobileResolution}
              onClick={onSubmit}
              type="submit"
              variant="contained"
              size="large"
            >
              Send me reset password instructions
            </Button>
          </Grid>
        </Grid>
      </form>
    </PageGridItem>
  );
}
