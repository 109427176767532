import * as React from 'react';

import Dialog         from '@material-ui/core/Dialog';
import DialogActions  from '@material-ui/core/DialogActions';
import DialogContent  from '@material-ui/core/DialogContent';
import DialogTitle    from '@material-ui/core/DialogTitle';
import IconButton     from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

import CloseIcon    from '@styles/icons/Close';
import DefaultTheme from '@themes/DefaultTheme';

interface ContentActionDialogProps {
  onClose: () => void;
  open: boolean;
  title: React.ReactNode;
  content: React.ReactNode;
  action: React.ReactNode | null;
}

const useStyles = makeStyles((theme: DefaultTheme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function ContentActionDialog({
  onClose,
  open,
  title,
  content,
  action
}: ContentActionDialogProps): JSX.Element {
  const classes = useStyles();

  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title">
        {title}
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon top="1px" />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        {content}
      </DialogContent>

      {action && (
        <DialogActions>
          {action}
        </DialogActions>
      )}
    </Dialog>
  );
}
