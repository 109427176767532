import { UserInterface } from '@components/User/UserInterface';

const userInitialState: UserInterface = {
  id: '',
  username: '',
  email: '',
  hasOrphanPlaylists: false,
  unconfirmed_email: '',
  password: '',
  password_confirmation: '',
  current_password: '',
  region: '',
  providers: []
};

export default userInitialState;
