import * as React from 'react';
import Markdown from 'markdown-to-jsx';
import Grid from '@material-ui/core/Grid';

import markdownConfig from '@resources/markdownConfig';
import DisclaimerPageMarkdown from '@docs/disclaimer.md';

export default function Disclaimer(): JSX.Element {
  return (
    <Grid item xs={12}>
      <Markdown options={markdownConfig}>
        {DisclaimerPageMarkdown}
      </Markdown>
    </Grid>
  );
}
