import * as React from 'react';

import Box        from '@material-ui/core/Box';
import Button     from '@material-ui/core/Button';
import Grid       from '@material-ui/core/Grid';
import Link       from '@material-ui/core/Link';
import UploadIcon from '@material-ui/icons/Description';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import DefaultTheme from '@themes/DefaultTheme';
import Text         from '@styles/Text';

interface ImportPlaylistsProps {
  csrfToken: string;
  errorMessage: string;
  fileSizeLimit: string;
  formAction: string;
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  mb5: {
    marginBottom: spacing(5)
  },
  uploadInputBox: {
    marginBottom: spacing(5)
  },
  uploadInputField: {
    display: 'none'
  }
}));

export default function ImportPlaylists({
  csrfToken,
  errorMessage,
  fileSizeLimit,
  formAction
}: ImportPlaylistsProps): JSX.Element {
  const classes = useStyles();

  const handleOnChangeInput = () => {
    document.getElementById('uploadPlaylistForm').submit();
  };

  let feedback = null;
  if (errorMessage.length > 1) {
    feedback = (
      <Text type="label" color="red">
        {errorMessage}
      </Text>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.mb5}>
        <Text type="heading" className={classes.mb5}>
          Upload playlists
        </Text>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Text type="label">
              Here you can upload a CSV file and have it converted into a Playlists.cloud playlist.
              You can then export it to any connected streaming services.
            </Text>
            <br />
            <Text type="label">
              Before you upload a file, please ensure that:
            </Text>

            <ol>
              <li>
                <Text type="label">
                  It is a valid UTF-8 encoded CSV (comma-separated value) file
                </Text>
              </li>
              <li>
                <Text type="label">
                  It contains <strong>all</strong> of the following header columns, in this <strong>exact order</strong>:
                  <br />
                  (You must include all the columns below, even if you don't have data for them)
                  <ol>
                    <li>
                      artist
                    </li>
                    <li>
                      track_name
                    </li>
                    <li>
                      album_name
                    </li>
                    <li>
                      isrc
                      {' '}
                      <i>
                        (Data is optional)
                      </i>
                    </li>
                    <li>
                      release_date
                      {' '}
                      <i>
                        (Data is optional, expected format: YYYY-MM-DD)
                      </i>
                    </li>
                    <li>
                      album_release_date
                      {' '}
                      <i>
                        (Data is optional, expected format: YYYY-MM-DD)
                      </i>
                    </li>
                    <li>
                      disc_number
                      {' '}
                      <i>
                        (Data is optional)
                      </i>
                    </li>
                    <li>
                      track_number
                      {' '}
                      <i>
                        (Data is optional)
                      </i>
                    </li>
                  </ol>
                </Text>
              </li>
              <li>
                <Text type="label">
                  It is less than
                  {' '}
                  <strong>
                    {`${fileSizeLimit}mb`}
                  </strong>
                  {' '}
                  in size
                </Text>
              </li>
              <li>
                <Text type="label">
                  It is clean (no blank rows, extra headers, dummy data, etc)
                </Text>
              </li>
            </ol>

            <Text type="label">
              If the import is successful, the playlist will be named after the uploaded file, followed by a timestamp.
            </Text>

            <br />

            <Text type="label">
              You can only import one file at a time, this limit might change in the future.
            </Text>

            <br />

            <Text type="label">
              Although some columns are optional, providing valid data in these fields can enhance the accuracy of track identification.
            </Text>

            <br />

            <Text type="label" className={classes.mb5}>
              Feel free to
              {' '}
              <Link href="/upload-template-file.csv" target="_blank">
                download
              </Link>
              {' '}
              a template file to help guide you.
            </Text>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.uploadInputBox}>
              <form id="uploadPlaylistForm" method="post" action={formAction} enctype="multipart/form-data">
                <input type="hidden" name="authenticity_token" value={csrfToken} />
                <input
                  accept=".csv"
                  name="playlist_file"
                  className={classes.uploadInputField}
                  onChange={handleOnChangeInput}
                  id="contained-button-file"
                  type="file"
                />
                <label htmlFor="contained-button-file">
                  <Button
                    color="default"
                    component="span"
                    variant="contained"
                    size="large"
                    startIcon={<UploadIcon />}
                  >
                    Upload a CSV file
                  </Button>
                </label>
              </form>
            </Box>
            <Box>
              {feedback}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
