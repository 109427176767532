import * as React from 'react';

import Box                from '@material-ui/core/Box';
import Button             from '@material-ui/core/Button';
import ClearIcon          from '@material-ui/icons/Clear';
import DeleteIcon         from '@material-ui/icons/Delete';
import DeleteSelectedIcon from '@material-ui/icons/DeleteSweep';
import Grid               from '@material-ui/core/Grid';
import SelectVisibleIcon  from '@material-ui/icons/LibraryAddCheck';

import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import { PlaylistInterface } from '@components/Playlist/PlaylistInterface';

import ExportPlaylists    from '@components/ExportPlaylists/ExportPlaylists';
import ConfirmationDialog from '@components/common/ConfirmationDialog';
import UserContext        from '@contexts/UserContext';

import DefaultTheme from '@themes/DefaultTheme';

interface EditModeViewProps {
  onEditSelectAllClick: () => void;
  onEditDeselectAllClick: () => void;
  onDeleteSelectedPlaylists: () => void;
  onDeleteAllPlaylists: () => void;
  selectedIds: Array<PlaylistInterface['id']>;
}

const useStyles = makeStyles(({ breakpoints, colors, spacing }: DefaultTheme) => createStyles({
  deleteAllButton: {
    backgroundColor: colors.lightRed
  },
  deleteSelectedButton: {
    marginRight: spacing(2)
  },
  gridContainer: {
    marginBottom: spacing(2)
  },
  rightSideGridItem: {
    '& button': {
      [breakpoints.down('sm')]: {
        marginBottom: spacing(2)
      }
    },
    [breakpoints.up('md')]: {
      textAlign: 'right'
    }
  },
  selectedLabel: {
    display: 'inline-block',
    marginRight: spacing(2)
  }
}));

export default function EditMode({
  onEditSelectAllClick,
  onEditDeselectAllClick,
  onDeleteSelectedPlaylists,
  onDeleteAllPlaylists,
  selectedIds
}: EditModeViewProps): JSX.Element {
  const user    = React.useContext(UserContext);
  const classes = useStyles();

  const [promptDeleteAllConfirmation, setPromptDeleteAllConfirmation] = React.useState(false);
  const [promptDeleteSelectedConfirmation, setPromptDeleteSelectedConfirmation] = React.useState(false);

  return (
    <>
      <Grid alignItems="center" container className={classes.gridContainer} spacing={3}>
        <Grid item xs={12} md={5}>
          <Box className={classes.selectedLabel}>
            <strong>
              {selectedIds.length}
            </strong>
            {' '}
            Selected
          </Box>

          <Button
            onClick={onEditSelectAllClick}
            startIcon={<SelectVisibleIcon />}
            title="Selects all playlists from the current page"
          >
            Select all from page
          </Button>

          {selectedIds.length > 0 && (
            <Button
              onClick={onEditDeselectAllClick}
              startIcon={<ClearIcon />}
            >
              Deselect all
            </Button>
          )}
        </Grid>
        <Grid item xs={12} md={7} className={classes.rightSideGridItem}>
          {selectedIds.length > 0 && (
            <>
              <ExportPlaylists
                editMode
                hasOrphanPlaylists={user.hasOrphanPlaylists}
                selectedIds={selectedIds}
                userProviders={user.providers}
              />
              <Button
                className={classes.deleteSelectedButton}
                onClick={() => setPromptDeleteSelectedConfirmation(true)}
                startIcon={<DeleteSelectedIcon />}
                variant="contained"
              >
                Delete selected
              </Button>
            </>
          )}

          <Button
            className={classes.deleteAllButton}
            onClick={() => setPromptDeleteAllConfirmation(true)}
            startIcon={<DeleteIcon />}
            variant="contained"
          >
            Delete all playlists
          </Button>
        </Grid>
      </Grid>

      {promptDeleteAllConfirmation && (
        <ConfirmationDialog
          title="Delete All Playlists?"
          content={(
            <>
              Please note, this action cannot be undone and will remove all playlists that match your current filters.
              If no filters are selected, <strong>all</strong> playlists in your account will be deleted. Double-check
              your filters before proceeding to ensure the correct playlists are selected for deletion.
              <p>
                This action will only remove playlists from Playlists.cloud.
              </p>
              <p>
                Tip: Export your playlists as CSV files for backup.
              </p>
            </>
          )}
          confirmLabel="Delete playlists"
          onClose={() => setPromptDeleteAllConfirmation(false)}
          onConfirm={onDeleteAllPlaylists}
          isOpen={promptDeleteAllConfirmation}
        />
      )}

      {promptDeleteSelectedConfirmation && (
        <ConfirmationDialog
          title="Delete Selected Playlists?"
          content={(
            <>
              This action cannot be undone and will only remove playlists from Playlists.cloud.
            </>
          )}
          confirmLabel="Delete playlists"
          onClose={() => setPromptDeleteSelectedConfirmation(false)}
          onConfirm={onDeleteSelectedPlaylists}
          isOpen={promptDeleteSelectedConfirmation}
        />
      )}
    </>
  );
}
