import * as React from 'react';

import { UserErrorType }            from '@components/User/UserErrorType';
import { UserInterface }            from '@components/User/UserInterface';
import userInitialState             from '@components/User/UserInitialState';
import { WithUserAccountInterface } from '@components/User/WithUserAccountInterface';
import {
  EMAIL_REGEX,
  PASSWORD_MIN_LENGTH,
  USERNAME_MAX_LENGTH,
  USERNAME_MIN_LENGTH
} from '@resources/validationRules';

interface UserAccountState {
  errors: UserErrorType;
  user: UserInterface;
  saving: boolean;
}

interface UserAccountProps {
  csrfToken: string;
  email?: string;
  errors: UserErrorType;
  formAction: string;
  region?: string;
  username?: string;
  user?: UserInterface;
}

const errorsInitialState = {
  ...userInitialState,
  currentPassword: '',
  other: ''
};

export default function withUserAccount(
  WrappedComponent: React.ComponentType<WithUserAccountInterface>
): React.ComponentClass {
  return class UserAccount extends React.Component<UserAccountProps, UserAccountState> {
    private formRef = React.createRef<HTMLFormElement>();

    static defaultProps = {
      email: '',
      region: '',
      username: '',
      user: null
    };

    constructor(props: UserAccountProps) {
      super(props);

      const {
        email,
        errors,
        region,
        username,
        user
      } = props;

      const parsedErrors = {};

      if (errors) {
        Object.entries(errors)
          .forEach((error) => {
            const [k, v] = error;
            parsedErrors[k] = v.join(', ');
          });
      }

      const userId = user && user.id ? user.id : '';

      this.state = {
        errors: {
          ...errorsInitialState,
          ...parsedErrors
        },
        user: {
          ...userInitialState,
          id: userId,
          email,
          region,
          username
        },
        saving: false
      };
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { id, value } = event.currentTarget;

      this.setState((prevState: UserAccountState): UserAccountState => ({
        ...prevState,
        user: {
          ...prevState.user,
          [id]: value
        }
      }));
    };

    handleRegionChange = (regionCode: string): void => {
      this.setState((prevState: UserAccountState): UserAccountState => ({
        ...prevState,
        user: {
          ...prevState.user,
          region: regionCode
        }
      }));
    };

    handleSave = (event: React.SyntheticEvent): boolean => {
      event.preventDefault();

      if (!this.isValid()) {
        return false;
      }

      if (this.formRef.current) {
        this.formRef.current.submit();
      }

      return true;
    };

    isValid(): boolean {
      const { user }      = this.state;
      const isNewProfile  = !user.id || user.id.length === 0;
      const errors        = { ...errorsInitialState };

      let isValid = true;

      if (user.username.length < USERNAME_MIN_LENGTH) {
        errors.username = `Your username must contain at least ${USERNAME_MIN_LENGTH} characters.`;
        isValid = false;
      }

      if (user.username.length > USERNAME_MAX_LENGTH) {
        errors.username = `Your username cannot exceed ${USERNAME_MAX_LENGTH} characters.`;
        isValid = false;
      }

      if (EMAIL_REGEX.test(user.email) === false) {
        errors.email = 'Invalid email provided.';
        isValid = false;
      }

      if (isNewProfile && user.password.length < PASSWORD_MIN_LENGTH) {
        errors.password = `Password is too short. It should contain at least ${PASSWORD_MIN_LENGTH} characters`;
        isValid = false;
      }

      if (isNewProfile && user.password.length > 0 && user.password !== user.password_confirmation) {
        errors.password_confirmation = 'Passwords don\'t match.';
        isValid = false;
      }

      // Users updating their profile must provide their password for the change to take effect
      if (!isNewProfile && user.current_password.length === 0) {
        errors.currentPassword = 'Please enter your current password to proceed.';
        isValid = false;
      }

      this.setState({ errors });

      return isValid;
    }

    render(): React.ReactNode {
      const {
        errors,
        user,
        saving
      } = this.state;

      const {
        csrfToken,
        formAction
      } = this.props;

      return (
        <WrappedComponent
          csrfToken={csrfToken}
          errors={errors}
          formAction={formAction}
          ref={this.formRef}
          user={user}
          onChange={this.handleChange}
          onRegionChange={this.handleRegionChange}
          onSave={this.handleSave}
          saving={saving}
        />
      );
    }
  };
}
