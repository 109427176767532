import * as React from 'react';

import ImportSummaryView          from '@components/Summaries/Import/ImportSummaryView';
import ImportSummaryMultiView     from '@components/Summaries/Import/ImportSummaryMultiView';
import { ImportSummaryInterface } from '@components/Summaries/Import/ImportSummaryInterface';
import { UserInterface }          from '@components/User/UserInterface';

interface ImportSummaryProps {
  importSummary: ImportSummaryInterface
  user: UserInterface;
}

export default class ImportSummary extends React.Component<ImportSummaryProps> {
  render(): React.ReactNode {
    const {
      importSummary
    } = this.props;

    const viewProps = {
      summary: importSummary
    };

    return importSummary.blobs.length > 1
      ? (<ImportSummaryMultiView {...viewProps} />)
      : (<ImportSummaryView {...viewProps} />)
    ;
  }
}
