import * as React from 'react';

import Button from '@material-ui/core/Button';

import { SpotifyProviderInterface } from './SpotifyProviderInterface';

interface ConnectSpotifyProps {
  spotifyProvider: SpotifyProviderInterface;
  ssoState: string;
}

export default function ConnectSpotify(props: ConnectSpotifyProps): JSX.Element {
  const {
    spotifyProvider,
    ssoState
  } = props;

  const {
    authUri,
    clientId,
    redirectUri,
    scopes
  } = spotifyProvider;

  const spotifyAuthUrl = `${authUri}?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}`
    + `&state=${ssoState}&scope=${scopes}`;

  return (
    <Button
      href={spotifyAuthUrl}
      color="primary"
      variant="contained"
      fullWidth
    >
      Connect to Spotify
    </Button>
  );
}
