import * as React from 'react';

import ConnectAmazonMusic from '@components/Providers/AmazonMusic/ConnectAmazonMusic';
import { AmazonMusicProviderInterface } from '@components/Providers/AmazonMusic/AmazonMusicProviderInterface';

interface AmazonMusicProviderProps {
  provider: AmazonMusicProviderInterface;
  ssoState: string;
}

export default function AmazonMusicProvider({
  provider,
  ssoState
}: AmazonMusicProviderProps): JSX.Element {
  return (
    <ConnectAmazonMusic amazonMusicProvider={provider} ssoState={ssoState} />
  );
}
