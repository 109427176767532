import * as React from 'react';

import ConnectAppleMusic from '@components/Providers/AppleMusic/ConnectAppleMusic';
import { AppleMusicProviderInterface } from '@components/Providers/AppleMusic/AppleMusicProviderInterface';

interface AppleProviderProps {
  provider: AppleMusicProviderInterface;
}

export default function AppleMusicProvider({ provider }: AppleProviderProps): JSX.Element {
  return (
    <ConnectAppleMusic appleMusicProvider={provider} />
  );
}
