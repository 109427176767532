import * as React from 'react';

import Button       from '@material-ui/core/Button';
import Card         from '@material-ui/core/Card';
import CardActions  from '@material-ui/core/CardActions';
import CardContent  from '@material-ui/core/CardContent';
import Grid         from '@material-ui/core/Grid';
import LinkOffIcon  from '@material-ui/icons/LinkOff';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import connectProviderComponents  from '@components/Providers/common/connectProviderComponents';
import useDisconnectProvider      from '@components/Providers/common/useDisconnectProvider';
import ProviderLogo               from '@components/Providers/common/ProviderLogo';
import UserProviderHelper         from '@resources/helpers/UserProviderHelper';
import { ProvidersInterface }     from '@components/Providers/ProvidersInterface';
import { UserInterface }          from '@components/User/UserInterface';

import DefaultTheme from '@themes/DefaultTheme';
import Text         from '@styles/Text';

interface ImportHomeProps {
  providers: ProvidersInterface;
  ssoState: string;
  user: UserInterface;
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  heading: {
    marginBottom: spacing(5)
  },
  headingGrid: {
    marginBottom: spacing(5)
  },
  actionGrid: {
    marginBottom: spacing(5)
  }
}));

export default function IntegrationsHome({
  providers,
  ssoState,
  user
}: ImportHomeProps): JSX.Element {
  const classes = useStyles();

  const providerComponents  = connectProviderComponents(providers, ssoState);
  const userProvidersHelper = new UserProviderHelper(user.providers);
  const { onDisconnect }    = useDisconnectProvider();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className={classes.headingGrid}>
        <Text type="heading" className={classes.heading}>
          Integrations
        </Text>

        <Text type="displayXs">
          Connect your account to a supported music streaming service.
        </Text>
      </Grid>

      {Object.keys(providers).map((provider: string) => {
        const userProvider = userProvidersHelper.fromSource(provider);
        const actionButton = userProvidersHelper.isConnected(provider)
          ? (
            <Button
              fullWidth
              onClick={() => onDisconnect(provider)}
              startIcon={<LinkOffIcon />}
              variant="contained"
            >
              {`Disconnect from ${userProvider.name}`}
            </Button>
          ) : (
            providerComponents[provider]
          )
        ;

        return (
          <Grid item xs={12} sm={3} key={provider}>
            <Card>
              <CardContent>
                <ProviderLogo providerSource={provider} height={40} />
              </CardContent>
              <CardActions>
                {actionButton}
              </CardActions>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}
