import * as React from 'react';

import Table      from '@material-ui/core/Table';
import TableBody  from '@material-ui/core/TableBody';
import TableCell  from '@material-ui/core/TableCell';
import TableHead  from '@material-ui/core/TableHead';
import TableRow   from '@material-ui/core/TableRow';

import { SummaryTracksStatsInterface } from '@components/Summaries/common/SummaryTracksStatsInterface';

import Text from '@styles/Text';

interface TrackListProps {
  trackStats: SummaryTracksStatsInterface;
}

export default function TrackList({ trackStats }: TrackListProps): JSX.Element {
  let viewBlock = (
    <>
      -
    </>
  );

  if (trackStats.tracks.length > 0) {
    viewBlock = (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Artist
            </TableCell>
            <TableCell>
              Name
            </TableCell>
            <TableCell>
              Album name
            </TableCell>
            <TableCell>
              Duration
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {trackStats.tracks.map((track, idx): JSX.Element => (
            <TableRow key={`tsl_${idx}`}>
              <TableCell>
                <Text type="label">
                  {track.artist}
                </Text>
              </TableCell>
              <TableCell>
                <Text type="label">
                  {track.name}
                </Text>
              </TableCell>
              <TableCell>
                <Text type="label">
                  {track.albumName}
                </Text>
              </TableCell>
              <TableCell>
                <Text type="label">
                  {track.durationMs}
                </Text>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  return viewBlock;
}
