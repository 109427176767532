import request from 'superagent';

import {
  BASE_URL,
  genericApiResponse
} from '@api/BaseApi';

const ENDPOINT = `${BASE_URL}/playlist_tracks`;

export default class PlaylistTrackApi {
  static deletePlaylistTracks(
    playlist_id: string,
    track_ids: Array<string>
  ): Promise<{ body: genericApiResponse }> {
    return request
      .del(`${ENDPOINT}/${playlist_id}`)
      .send({
        track_ids
      })
    ;
  }
}
