import styled         from 'styled-components';
import FormHelperText from '@material-ui/core/FormHelperText';

export default styled(FormHelperText)`
  && {
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
  }
`;
