import * as React from 'react';

import Box  from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import EditAccount    from '@components/User/EditAccount';
import DeleteAccount  from '@components/User/DeleteAccount';

import { WithUserAccountInterface } from '@components/User/WithUserAccountInterface';

import Text         from '@styles/Text';
import PageGridItem from '@styles/PageGridItem';
import DefaultTheme from '@themes/DefaultTheme';

const useStyles = makeStyles(({ spacing, colors }: DefaultTheme) => createStyles({
  headingBox: {
    marginBottom: spacing(2)
  },
  deleteBox: {
    borderTop: `1px solid ${colors.lightBlack}`,
    marginTop: spacing(5),
    paddingTop: spacing(5)
  }
}));

export default function ManageAccount(props: WithUserAccountInterface): JSX.Element {
  const classes = useStyles();

  return (
    <PageGridItem>
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className={classes.headingBox}>
              <Text type="heading">
                My Account
              </Text>
            </Box>
          </Grid>
        </Grid>

        <EditAccount {...props} />

        <Box className={classes.deleteBox}>
          <DeleteAccount {...props} />
        </Box>
      </>
    </PageGridItem>
  );
}
