import { UserProvidersInterface } from '@components/User/UserInterface';

export default class UserProviderHelper {
  userProviders = []

  constructor(userProviders: Array<UserProvidersInterface>) {
    this.userProviders = userProviders;
  }

  fromSource(source: string): UserProvidersInterface | null {
    const matches = this.userProviders.filter((up: UserProvidersInterface) => up.source === source);

    if (matches.length <= 0) {
      return null;
    }

    return matches[0];
  }

  isConnected(source: string): boolean {
    const userProvider = this.fromSource(source);

    if (userProvider === null) {
      return false;
    }

    return userProvider.accessToken
      && userProvider.accessToken.length > 0
      && !userProvider.error
    ;
  }
}
