import * as React from 'react';

import Box          from '@material-ui/core/Box';
import Breadcrumbs  from '@material-ui/core/Breadcrumbs';
import Button       from '@material-ui/core/Button';
import Grid         from '@material-ui/core/Grid';
import Link         from '@material-ui/core/Link';
import TextField    from '@material-ui/core/TextField';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import { WithTwoFactorInterface } from '@components/User/TwoFactor/WithTwoFactorInterface';
import withTwoFactor from '@components/User/TwoFactor/withTwoFactor';

import DefaultTheme   from '@themes/DefaultTheme';
import PageGridItem   from '@styles/PageGridItem';
import Text           from '@styles/Text';

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  form: {
    marginTop: spacing(4)
  },
  headingBox: {
    marginBottom: spacing(2)
  }
}));

const DisableTwoFactor = React.forwardRef((props: WithTwoFactorInterface, ref: React.Ref<HTMLFormElement>): JSX.Element => {
  const classes = useStyles();

  const {
    csrfToken,
    formAction,
    isValid,
    otpChallenge,
    onChange,
    onSubmit
  } = props;

  const pageTitle = 'Disable 2-FA';

  return (
    <PageGridItem>
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/account">
                <Text type="label">
                  My Account
                </Text>
              </Link>

              <Link color="inherit" href="/account/2fa">
                <Text type="label">
                  Two-Factor Authentication
                </Text>
              </Link>

              <Text type="label" color="secondary">
                {pageTitle}
              </Text>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.headingBox}>
              <Text type="heading">
                {pageTitle}
              </Text>
            </Box>
            <Text type="label">
              Please enter the 6-digit code from your authenticator app to disable 2-FA.
            </Text>
          </Grid>
        </Grid>

        <form
          action={formAction}
          className={classes.form}
          method="post"
          onSubmit={(e: React.SyntheticEvent) => onSubmit(e, true)}
          noValidate
          ref={ref}
        >
          <input type="hidden" name="authenticity_token" value={csrfToken} />
          <input type="hidden" name="_method" value="delete" />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                error={otpChallenge.length > 0 && !isValid}
                name="user[otp_attempt]"
                id="otp_attempt"
                label="6 digits code"
                type="text"
                onChange={(e: React.SyntheticEvent<HTMLInputElement>) => onChange(e, true)}
                value={otpChallenge}
                inputProps={{ maxLength: 6, autoCorrect: 'off' }}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                onClick={(e: React.SyntheticEvent) => onSubmit(e, true)}
                type="submit"
                variant="contained"
                size="large"
              >
                Disable
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    </PageGridItem>
  );
});

export default withTwoFactor(DisableTwoFactor);
