import * as React from 'react';

import ConnectSpotify from '@components/Providers/Spotify/ConnectSpotify';
import { SpotifyProviderInterface } from '@components/Providers/Spotify/SpotifyProviderInterface';

interface SpotifyProviderProps {
  provider: SpotifyProviderInterface;
  ssoState: string;
}

export default function SpotifyProvider({
  provider,
  ssoState
}: SpotifyProviderProps): JSX.Element {
  return (
    <ConnectSpotify spotifyProvider={provider} ssoState={ssoState} />
  );
}
