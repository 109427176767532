import * as React from 'react';

import SwipeableViews     from 'react-swipeable-views';
import { autoPlay }       from 'react-swipeable-views-utils';
import Grid               from '@material-ui/core/Grid';
import MobileStepper      from '@material-ui/core/MobileStepper';
import useMediaQuery      from '@material-ui/core/useMediaQuery';
import Button             from '@material-ui/core/Button';
import KeyboardArrowLeft  from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import Text         from '@styles/Text';
import DefaultTheme from '@themes/DefaultTheme';

const useStyles = makeStyles(({ colors, spacing }: DefaultTheme) => createStyles({
  root: {
    flexGrow: 1
  },
  label: {
    marginBottom: spacing(1)
  },
  description: {
    color: colors.grey
  },
  img: {
    display: 'block',
    overflow: 'hidden',
    width: '100%',
  },
  stepper: {
    backgroundColor: 'inherit',
    '& .MuiMobileStepper-dots': {
      margin: `${spacing(2)}px auto`
    }
  }
}));

const galleryInterval = 8000;
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const steps = [
  {
    label: 'Dashboard',
    description: 'Connect to supported music streaming services.',
    imageName: '01-dashboard'
  },
  {
    label: 'Your playlists',
    description: 'Sync your playlists across supported music streaming services, transfer them between platforms, export them as static files for personal backup, and more.',
    imageName: '02-playlists'
  },
  {
    label: 'Playlist details',
    description: 'See basic information from all tracks present in your playlist.',
    imageName: '03-playlist-details'
  },
  {
    label: 'Upload playlists',
    description: 'Create playlists from CSV files.',
    imageName: '06-upload-playlists'
  },
  {
    label: 'Export playlists',
    description: 'Export one or more playlists to another connected music streaming service (also referred to as a "provider").',
    imageName: '04-playlist-export'
  },
  {
    label: 'Export summary',
    description: 'See details about an export, including tracks that could not be exported.',
    imageName: '05-export-summary'
  },
];

export default function HomeGallery(): JSX.Element {
  const classes = useStyles();
  const isMobileResolution = useMediaQuery('(max-width:767px)');
  const [activeStep, setActiveStep] = React.useState(0);

  const maxSteps = steps.length;
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Text type="displayMd" className={classes.label}>
          {steps[activeStep].label}
        </Text>

        <Text type="displayXs" className={classes.description}>
          {steps[activeStep].description}
        </Text>
      </Grid>
      <Grid item xs={12} md={8}>
        <div className={classes.root}>
          <AutoPlaySwipeableViews
            axis="x"
            index={activeStep}
            interval={galleryInterval}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {steps.map((step, index) => {
              const imageFilename = isMobileResolution ? `${step.imageName}-mobile` : step.imageName;
              const imageFilePath = `/images/${imageFilename}.jpg`;

              return (
                <div key={step.label}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <img className={classes.img} src={imageFilePath} alt={step.label} title={step.label} />
                  ) : null}
                </div>
              );
            })}
          </AutoPlaySwipeableViews>
        </div>
      </Grid>
      <Grid item xs={12}>
        <MobileStepper
          variant="dots"
          steps={steps.length}
          position="static"
          activeStep={activeStep}
          className={classes.stepper}
          nextButton={(
            <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
              <KeyboardArrowRight />
            </Button>
          )}
          backButton={(
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
              <KeyboardArrowLeft />
            </Button>
          )}
        />
      </Grid>
    </Grid>
  );
}
