import * as React from 'react';

import Accordion        from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Box              from '@material-ui/core/Box';
import ExpandMoreIcon   from '@material-ui/icons/ExpandMore';
import Table            from '@material-ui/core/Table';
import TableHead        from '@material-ui/core/TableHead';
import TableBody        from '@material-ui/core/TableBody';
import TableCell        from '@material-ui/core/TableCell';
import TableRow         from '@material-ui/core/TableRow';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import TrackList from '@components/Summaries/common/TrackList';
import { ImportBlobSummaryInterface } from '@components/Summaries/Import/ImportSummaryInterface';

import Text         from '@styles/Text';
import DefaultTheme from '@themes/DefaultTheme';

interface ImportedBlobSummaryProps {
  blob: ImportBlobSummaryInterface;
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  failedBox: {
    marginTop: spacing(4)
  },
  trackCount: {
    marginLeft: spacing(2)
  },
  validTrackCount: {
    marginRight: spacing(2)
  }
}));

export default function ImportedBlobSummary({ blob }: ImportedBlobSummaryProps): JSX.Element {
  const classes     = useStyles();
  const { playlistId, summary } = blob;

  const importedBlock = playlistId ? (
    <Accordion expanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Text type="displayXs" color="green" weight={700} className={classes.validTrackCount}>
          {summary.imported.total}
        </Text>
        <Text type="displayXs">
          Tracks identified
        </Text>
      </AccordionSummary>
      <AccordionDetails>
        <TrackList trackStats={summary.imported} />
      </AccordionDetails>
    </Accordion>
  ) : (
    <Text type="displayXs">
      No tracks identified from the uploaded file, review the errors below and try again.
    </Text>
  );

  const failedBlock = (title: string, rows: Array<number>): JSX.Element => (
    <Accordion key={title}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        <Text type="displayXs">
          {title}
        </Text>
        <Text type="displayXs" color="green" weight={700} className={classes.trackCount}>
          {rows.length}
        </Text>
      </AccordionSummary>
      <AccordionDetails>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Row #
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow>
                <TableCell>
                  <Text type="label">
                    {row}
                  </Text>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );

  const failedRowsBlock = summary.totalFailedRows === 0 ? null : (
    <>
      {summary.failedRows.trackNotFound.length > 0 && failedBlock('Track not found', summary.failedRows.trackNotFound)}
      {summary.failedRows.trackNotExactMatch.length > 0 && failedBlock('Not an exact match', summary.failedRows.trackNotExactMatch)}
      {summary.failedRows.trackInvalidIsrc.length > 0 && failedBlock('Invalid ISRC code', summary.failedRows.trackInvalidIsrc)}
      {summary.failedRows.rowMissingRequiredColumns.length > 0 && failedBlock('Missing required CSV columns', summary.failedRows.rowMissingRequiredColumns)}
      {summary.failedRows.rowGenericError.length > 0 && failedBlock('Unable to process row', summary.failedRows.rowGenericError)}
    </>
  );

  return (
    <>
      {importedBlock}
      {summary.totalFailedRows > 0 && (
        <Box className={classes.failedBox}>
          <Text type="displayMd">
            Failed CSV rows
          </Text>

          <Text type="label" color="secondary">
            Failure reason (expand to see row numbers) - Total #
          </Text>

          <Box className={classes.failedBox}>
            {failedRowsBlock}
          </Box>
        </Box>
      )}
    </>
  );
}
