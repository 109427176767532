import * as React from 'react';

import Button from '@material-ui/core/Button';

import { DeezerProviderInterface } from 'app/javascript/components/Providers/Deezer/DeezerProviderInterface';

interface ConnectDeezerProps {
  deezerProvider: DeezerProviderInterface;
  ssoState: string;
}

export default function ConnectDeezer(props: ConnectDeezerProps): JSX.Element {
  const {
    deezerProvider,
    ssoState
  } = props;

  const {
    authUri,
    appId,
    redirectUri,
    permissions
  } = deezerProvider;

  // @todo ssoState required?
  const deezerAuthUrl = `${authUri}?app_id=${appId}&redirect_uri=${redirectUri}&perms=${permissions}&state=${ssoState}`;

  return (
    <Button
      href={deezerAuthUrl}
      color="primary"
      variant="contained"
      fullWidth
    >
      Connect to Deezer
    </Button>
  );
}
