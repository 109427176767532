import * as React from 'react';

import Button         from '@material-ui/core/Button';
import Fade           from '@material-ui/core/Fade';
import FormControl    from '@material-ui/core/FormControl';
import Grid           from '@material-ui/core/Grid';
import TextField      from '@material-ui/core/TextField';
import useMediaQuery  from '@material-ui/core/useMediaQuery';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import PasswordInput    from '@components/common/form/PasswordInput';
import RegionSelection  from '@components/common/form/RegionSelection';
import withUserAccount  from '@components/User/withUserAccount';

import LoadingIndicator   from '@components/common/LoadingIndicator';
import FormHelperText     from '@styles/form/FormHelperText';
import Text               from '@styles/Text';
import DefaultTheme       from '@themes/DefaultTheme';

import { WithUserAccountInterface } from '@components/User/WithUserAccountInterface';

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  form: {
    marginTop: spacing(5)
  },
  gridSpacing: {
    marginTop: spacing(5)
  },
  headingBox: {
    marginBottom: spacing(2)
  },
  rightAlignedGridItem: {
    textAlign: 'right'
  },
  submitBtn: {
    marginTop: spacing(2)
  }
}));

const EditAccount = React.forwardRef((props: WithUserAccountInterface, ref: React.Ref<HTMLFormElement>): JSX.Element => {
  const classes = useStyles();
  const isMobileResolution = useMediaQuery('(max-width:767px)');

  const {
    csrfToken,
    errors,
    formAction,
    onChange,
    onRegionChange,
    onSave,
    user,
    saving
  } = props;

  const onClickManage2Fa = (e) => {
    e.preventDefault();
    window.location.href = '/account/2fa';
  };

  return (
    <form
      action={formAction}
      className={classes.form}
      method="post"
      onSubmit={onSave}
      ref={ref}
      noValidate
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl
            error={errors.username.length > 0}
            fullWidth
          >
            <TextField
              error={errors.username.length > 0}
              name="user[username]"
              id="username"
              label="Username"
              type="text"
              onChange={onChange}
              value={user.username}
              variant="outlined"
              required
            />

            {errors.username.length > 0 && (
              <Fade in>
                <FormHelperText>
                  {errors.username}
                </FormHelperText>
              </Fade>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            error={errors.email.length > 0}
            fullWidth
          >
            <TextField
              error={errors.email.length > 0}
              name="user[email]"
              id="email"
              label="Email Address"
              type="email"
              onChange={onChange}
              value={user.email}
              variant="outlined"
              required
            />

            {errors.email.length > 0 && (
              <Fade in>
                <FormHelperText>
                  {errors.email}
                </FormHelperText>
              </Fade>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            error={errors.region.length > 0}
            fullWidth
          >
            <RegionSelection
              onChange={onRegionChange}
              value={user.region}
            />

            {errors.region.length > 0 && (
              <Fade in>
                <FormHelperText>
                  {errors.region}
                </FormHelperText>
              </Fade>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} className={classes.gridSpacing}>
          <Text type="label">
            {'Leave password blank if you don\'t wish to change it.'}
          </Text>
        </Grid>
        <Grid item xs={12}>
          <PasswordInput
            fieldId="password"
            fieldName="user[password]"
            fieldLabel="Password"
            fieldValue={user.password}
            onChangeHandler={onChange}
            error={errors.password}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordInput
            fieldId="password_confirmation"
            fieldName="user[password_confirmation]"
            fieldLabel="Confirm Password"
            fieldValue={user.password_confirmation}
            onChangeHandler={onChange}
            error={errors.password_confirmation}
          />
        </Grid>
        <Grid item xs={12} className={classes.gridSpacing}>
          <PasswordInput
            fieldId="current_password"
            fieldName="user[current_password]"
            fieldLabel="Current Password (Required to confirm changes)"
            fieldValue={user.current_password}
            onChangeHandler={onChange}
            error={errors.currentPassword}
            required
          />

          <input type="hidden" name="authenticity_token" value={csrfToken} />
          <input type="hidden" name="_method" value="put" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            className={classes.submitBtn}
            color="primary"
            disabled={saving}
            fullWidth={isMobileResolution}
            onClick={onSave}
            type="submit"
            variant="contained"
            size="large"
          >
            {saving && <LoadingIndicator />}
            Update
          </Button>
        </Grid>
        <Grid item className={classes.rightAlignedGridItem} xs={12} sm={6}>
          <Button
            className={classes.submitBtn}
            color="secondary"
            fullWidth={isMobileResolution}
            onClick={onClickManage2Fa}
            variant="contained"
            size="large"
          >
            Manage Two-Factor Authentication
          </Button>
        </Grid>
      </Grid>
    </form>
  );
});

export default withUserAccount(EditAccount);
