import * as React from 'react';

import Box  from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import Text         from '@styles/Text';
import DefaultTheme from '@themes/DefaultTheme';

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  topSpacing: {
    marginTop: spacing(3)
  }
}));

export default function NoPlaylistsMessage(): JSX.Element {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Box className={classes.topSpacing}>
        <Text type="label">
          No playlists found. Try
          {' '}
          <Link href="/integrations">
            connecting to a provider
          </Link>
          {' '}
          and synchronizing it with your account.
        </Text>
      </Box>
    </Grid>
  );
}
