import * as React from 'react';

import BottomLeftSnackbar from '@themes/styles/BottomLeftSnackbar';
import FlashContext       from '@contexts/FlashContext';

const severityTypes = {
  alert: 'error',
  error: 'error',
  notice: 'info',
  danger: 'warning',
  success: 'success',
  warning: 'warning'
};

interface FlashMessagesProps {
  open: boolean;
}

export default function FlashMessages({ open }: FlashMessagesProps): JSX.Element {
  const messages = [];
  const {
    dismissFlashMessage,
    flashes
  } = React.useContext(FlashContext);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dismissFlashMessage();
  };

  flashes.forEach((flash, index) => {
    const [type, msg] = flash;
    const severity = severityTypes[type] || 'info';

    messages.push(
      <BottomLeftSnackbar
        key={index}
        onClose={handleClose}
        open={open}
        severity={severity}
        msg={msg}
      />
    );
  });

  return (
    <>
      {messages}
    </>
  );
}
