import * as React from 'react';

import Box        from '@material-ui/core/Box';
import Paper      from '@material-ui/core/Paper';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import DefaultTheme from '@themes/DefaultTheme';

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  box: {
    padding: spacing(5)
  },
  root: {
    margin: spacing(5, 0)
  }
}));

interface PaddedPaperProps {
  children: JSX.Element;
}

export default function PaddedPaper({ children }: PaddedPaperProps): JSX.Element {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Box className={classes.box}>
        {children}
      </Box>
    </Paper>
  );
}
