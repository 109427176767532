import * as React from 'react';

import NotificationsMenuView  from '@components/Notifications/NotificationsMenuView';
import NotificationsChannel   from '@channels/NotificationsChannel';

import { NotificationInterface }           from '@components/Notifications/NotificationInterface';
import { NotificationCollectionInterface } from '@components/Notifications/NotificationCollectionInterface';

interface NotificationsMenuState {
  checksum: string;
  notifications: Array<NotificationInterface>;
  unreadCount: number;
}

interface NotificationsMenuProps {
  notificationCollection: NotificationCollectionInterface;
}

export default class NotificationsMenu extends React.Component<NotificationsMenuProps, NotificationsMenuState> {
  notificationChannel = new NotificationsChannel();

  constructor(props: NotificationsMenuProps) {
    super(props);

    const { checksum, data } = props.notificationCollection;

    this.state = {
      checksum,
      notifications: data,
      unreadCount: this.calcUnreadCount(data)
    };
  }

  componentDidMount(): void {
    this.notificationChannel.subscribe(this.handleOnWatchSuccess);
  }

  componentWillUnmount(): void {
    this.notificationChannel.unsubscribe();
  }

  handleOnWatchSuccess = (notificationCollection: NotificationCollectionInterface): void => {
    const { checksum, data } = notificationCollection;

    if (checksum !== this.state.checksum) {
      this.setState({
        checksum,
        unreadCount: this.calcUnreadCount(data),
        notifications: data
      });
    }
  }

  handleOnDismissClick = (notificationId: string): void => {
    this.notificationChannel.acknowledge(notificationId);
  }

  handleOnDismissAllClick = (): void => {
    this.notificationChannel.acknowledgeAll();
  }

  calcUnreadCount(notifications: Array<NotificationInterface>): number {
    return notifications.filter((n: NotificationInterface) => !n.attributes.acknowledgedOn).length;
  }

  render(): React.ReactNode {
    const {
      notifications,
      unreadCount
    } = this.state;

    return (
      <NotificationsMenuView
        notifications={notifications}
        onDismissClick={this.handleOnDismissClick}
        onDismissAllClick={this.handleOnDismissAllClick}
        unreadCount={unreadCount}
      />
    );
  }
}
