import { createTheme } from '@material-ui/core/styles';

export const colorHighlights = {
  blue:         '#033ab6',
  green:        '#2cb67d',
  indigo:       '#2A628F',
  honey:        '#b3913b',
  red:          '#f44336',
  lightRed:     '#cc5e5e',
  darkRed:      '#9B1208',
  purple:       '#333452',
  primary:      '#7f5af0',
  secondary:    '#72757e'
};

const DefaultTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },

  spacing: (factor) => [0, 5, 10, 15, 20, 25][factor],

  typography: {
    fontFamily: [
      '"Barlow Semi Condensed"',
      'Helvetica',
      'Arial',
    ].join(',')
  },

  colors: {
    default:        '#fffffe',
    black:          '#16161a',
    lightBlack:     '#242629',
    grey:           '#94a1b2',
    lightGrey:      '#f4f6f8',
    lightYellow:    '#fffaf3',
    dark:           '#010101',
    white:          '#fffffe',
    ...colorHighlights
  },

  palette: {
    type: 'dark',
    background: {
      paper: '#242629'
    },
    primary: {
      main: '#7f5af0'
    },
    secondary: {
      main: '#72757e'
    }
  }
});

export default DefaultTheme;
