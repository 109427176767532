import * as React from 'react';
import Markdown from 'markdown-to-jsx';
import Grid from '@material-ui/core/Grid';

import markdownConfig from '@resources/markdownConfig';
import PrivacyPageMarkdown from '@docs/privacy.md';

export default function Privacy(): JSX.Element {
  return (
    <Grid item xs={12}>
      <Markdown options={markdownConfig}>
        {PrivacyPageMarkdown}
      </Markdown>
    </Grid>
  );
}
