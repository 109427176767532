import * as React from 'react';

import Checkbox                 from '@material-ui/core/Checkbox';
import TextField                from '@material-ui/core/TextField';
import Autocomplete             from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon             from '@material-ui/icons/CheckBox';
import {
  createStyles,
  makeStyles
} from '@material-ui/styles';

import { UserPlaylistSourcesInterface } from '@components/User/UserPlaylistSourcesInterface';
import DefaultTheme from '@themes/DefaultTheme';

const icon        = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface SourceSelectorProps {
  selectedSourceFilters: Array<UserPlaylistSourcesInterface>;
  onSourceFilterChange: (sourceFilters: Array<string>) => void;
  userPlaylistSources: Array<UserPlaylistSourcesInterface>;
}

const useStyles = makeStyles(({ breakpoints }: DefaultTheme) => createStyles({
  root: {
    [breakpoints.down('md')]: {
      width: '100%'
    },
    [breakpoints.up('lg')]: {
      width: 300
    }
  }
}));

export default function SourceSelector({
  selectedSourceFilters,
  onSourceFilterChange,
  userPlaylistSources
}: SourceSelectorProps): JSX.Element {
  const classes = useStyles();

  const handleOnChange = (event, sourceFilters: Array<UserPlaylistSourcesInterface>, reason: string): void => {
    const parsedSelection = sourceFilters.map((sourceFilter) => sourceFilter.value);
    onSourceFilterChange(parsedSelection);
  };

  return (
    <Autocomplete
      multiple
      id="playlist-source-selection"
      className={classes.root}
      options={userPlaylistSources}
      defaultValue={selectedSourceFilters}
      ChipProps={{ color: 'default', variant: 'outlined' }}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      getOptionSelected={(opt, value) => opt.value === value.value}
      onChange={handleOnChange}
      size="small"
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </>
      )}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Filter by sources" />
      )}
    />
  );
}
