import * as React from 'react';

import Divider            from '@material-ui/core/Divider';
import Hidden             from '@material-ui/core/Hidden';
import List               from '@material-ui/core/List';
import ListItem           from '@material-ui/core/ListItem';
import MenuList           from '@material-ui/core/MenuList';
import MenuItem           from '@material-ui/core/MenuItem';
import Grow               from '@material-ui/core/Grow';
import Paper              from '@material-ui/core/Paper';
import Popper             from '@material-ui/core/Popper';
import ClickAwayListener  from '@material-ui/core/ClickAwayListener';
import IconButton         from '@material-ui/core/IconButton';
import HelpIcon           from '@material-ui/icons/HelpOutline';
import {
  createStyles,
  makeStyles
} from '@material-ui/styles';

import { MenuItemInterface } from '@components/Header/MenuItems/MenuItemInterface';

import DefaultTheme   from '@themes/DefaultTheme';
import Text           from '@styles/Text';

interface CommonMenuItemsProps {
  activeLink: string;
}

const items: Array<MenuItemInterface> = [
  {
    key: 'about',
    label: 'About',
    path: '/about'
  },
  {
    key: 'help',
    label: 'Help',
    path: '/help'
  },
  {
    key: 'faq',
    label: 'FAQ',
    path: '/faq'
  },
  {
    key: 'blog',
    label: 'Blog',
    path: 'https://ko-fi.com/zzzrod/posts',
    target: '_blank'
  },
  {
    key: 'divider1',
    label: 'divider',
    path: ''
  },
  {
    key: 'contact',
    label: 'Contact',
    path: '/contact'
  },
  {
    key: 'disclaimer',
    label: 'Disclaimer',
    path: '/disclaimer'
  },
  {
    key: 'privacy',
    label: 'Privacy',
    path: '/privacy'
  }
];

const useStyles = makeStyles(({ colors }: DefaultTheme) => createStyles({
  paper: {
    backgroundColor: colors.lightBlack
  }
}));

export default function CommonMenuItems(props: CommonMenuItemsProps): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const open    = Boolean(anchorEl);

  const handleMenu = (event): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  // const { activeLink } = props;

  return (
    <>
      <Hidden xsDown>
        <IconButton onClick={handleMenu}>
          <HelpIcon color="secondary" fontSize="medium" />
        </IconButton>
        <Popper open={open} anchorEl={anchorEl} transition disablePortal>
          {({ TransitionProps }) => (
            <Grow
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...TransitionProps}
              style={{ transformOrigin: 'center bottom' }}
            >
              <Paper id="menu-list-grow" className={classes.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} onClick={handleClose}>
                    {items.map((item: MenuItemInterface): JSX.Element => (item.label === 'divider' ? (
                      <Divider key={item.key} />
                    ) : (
                      <MenuItem
                        component="a"
                        key={item.key}
                        href={item.path}
                        target={item.target}
                      >
                        <Text color="lightGrey" type="label" weight={400}>
                          {item.label}
                        </Text>
                      </MenuItem>
                    )))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Hidden>
      <Hidden smUp>
        <Divider />
        <List>
          {items.map((item: MenuItemInterface): JSX.Element => (item.label === 'divider' ? (
            <Divider key={item.key} />
          ) : (
            <ListItem
              button
              component="a"
              key={item.key}
              href={item.path}
            >
              <Text color="lightGrey" type="label" weight={400}>
                {item.label}
              </Text>
            </ListItem>
          )))}
        </List>
      </Hidden>
    </>
  );
}
