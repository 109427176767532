import * as React from 'react';

import Box            from '@material-ui/core/Box';
import Button         from '@material-ui/core/Button';
import FormControl    from '@material-ui/core/FormControl';
import Grid           from '@material-ui/core/Grid';
import TextField      from '@material-ui/core/TextField';
import useMediaQuery  from '@material-ui/core/useMediaQuery';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import { WithTwoFactorInterface } from '@components/User/TwoFactor/WithTwoFactorInterface';
import withTwoFactor from '@components/User/TwoFactor/withTwoFactor';

import DefaultTheme       from '@themes/DefaultTheme';
import PageGridItem       from '@styles/PageGridItem';
import Text               from '@styles/Text';

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  form: {
    marginTop: spacing(5)
  },
  headingBox: {
    marginBottom: spacing(2)
  },
  submitBtn: {
    marginTop: spacing(2)
  }
}));

const TwoFactorLogin = React.forwardRef((props: WithTwoFactorInterface, ref: React.Ref<HTMLFormElement>): JSX.Element => {
  const classes = useStyles();
  const isMobileResolution = useMediaQuery('(max-width:767px)');

  const {
    csrfToken,
    formAction,
    isValid,
    otpChallenge,
    onChange,
    onSubmit
  } = props;

  return (
    <PageGridItem>
      <form
        action={formAction}
        className={classes.form}
        method="post"
        onSubmit={(e: React.SyntheticEvent) => onSubmit(e, false)}
        ref={ref}
        noValidate
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className={classes.headingBox}>
              <Text type="heading">
                Two Factor Authentication
              </Text>
            </Box>

            <Box className={classes.headingBox}>
              <Text type="displayXs">
                Enter the 6-digit code from your 2FA app, or a backup code if you lost access.
              </Text>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                error={otpChallenge.length > 0 && !isValid}
                name="user[otp_attempt]"
                id="otp_attempt"
                label="One-time password or backup code"
                type="text"
                onChange={(e: React.SyntheticEvent<HTMLInputElement>) => onChange(e, false)}
                value={otpChallenge}
                inputProps={{ maxLength: 32, autoCorrect: 'off' }}
                variant="outlined"
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <input type="hidden" name="authenticity_token" value={csrfToken} />

            <Button
              className={classes.submitBtn}
              color="primary"
              fullWidth={isMobileResolution}
              onClick={(e: React.SyntheticEvent) => onSubmit(e, false)}
              type="submit"
              variant="contained"
              size="large"
            >
              Log In
            </Button>
          </Grid>
        </Grid>
      </form>
    </PageGridItem>
  );
});

export default withTwoFactor(TwoFactorLogin);
