import Text from '@styles/Text';

const markdownConfig = {
  overrides: {
    h1: {
      component: Text,
      props: {
        style: {
          marginBottom: 20
        },
        type: 'heading'
      }
    },
    h2: {
      component: Text,
      props: {
        style: {
          marginTop: 20,
          marginBottom: 20
        },
        type: 'displayMd'
      }
    },
    h3: {
      component: Text,
      props: {
        style: {
          marginTop: 20,
          marginBottom: 20
        },
        type: 'displaySm'
      }
    },
    p: {
      component: Text,
      props: {
        type: 'label'
      }
    },
    li: {
      component: Text,
      props: {
        component: 'li',
        type: 'label'
      }
    },
    hr: {
      props: {
        style: {
          borderColor: '#555555',
          borderTopStyle: 'none',
          // borderBottomStyle: 'groove',
          marginTop: 40,
          marginBottom: 40
        }
      }
    }
  }
};

export default markdownConfig;
