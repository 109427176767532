import * as React from 'react';

import SyncIcon from '@material-ui/icons/Sync';

import ProviderApi                      from '@api/ProviderApi';
import FlashContext                     from '@contexts/FlashContext';
import { UserPlaylistSourcesInterface } from '@components/User/UserPlaylistSourcesInterface';
import {
  defaultApiErrorMessage,
  genericApiResponse
} from '@api/BaseApi';

import SplitButton, { SplitButtonInterface } from '@themes/styles/form/SplitButton';

interface ResyncPlaylistsProps {
  userPlaylistSources: Array<UserPlaylistSourcesInterface>;
}

export default class ResyncPlaylists extends React.Component<ResyncPlaylistsProps> {
  static contextType = FlashContext;
  private readonly menuItems: Array<SplitButtonInterface>;

  constructor(props: ResyncPlaylistsProps) {
    super(props);

    const defaultMenuItems = [
      {
        title: 'Re-Synchronize all playlists',
        onClick: this.resyncAll
      }
    ];

    const providerMenuItems = props
      .userPlaylistSources
      .filter((providerSource: UserPlaylistSourcesInterface) => providerSource.value !== 'upload')
      .map((providerSource: UserPlaylistSourcesInterface): SplitButtonInterface => ({
        title: `Re-Synchronize all from ${providerSource.label}`,
        onClick: this.resyncProvider,
        optionalArg: providerSource.value
      }))
    ;

    this.menuItems = [...defaultMenuItems, ...providerMenuItems];
  }

  resyncAll = (): void => {
    this.syncProviders();
  }

  resyncProvider = (providerSource: string): void => {
    this.syncProviders(providerSource);
  }

  syncProviders(source: string = ''): void {
    ProviderApi
      .syncProviders(source)
      .then((res: { body: genericApiResponse }) => {
        const status = ['notice', res.body.status];
        this.context.flashMessage(status);
      })
      .catch((res) => {
        const message = res.response ? res.response.body.error : defaultApiErrorMessage;
        const error = ['alert', message];
        this.context.flashMessage(error);
      })
    ;
  }

  render(): React.ReactNode {
    return (
      <SplitButton
        items={this.menuItems}
        startIcon={<SyncIcon />}
      />
    );
  }
}
