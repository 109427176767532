import * as React from 'react';

import Button             from '@material-ui/core/Button';
import ButtonGroup        from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon  from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener  from '@material-ui/core/ClickAwayListener';
import Divider            from '@material-ui/core/Divider';
import Grow               from '@material-ui/core/Grow';
import Paper              from '@material-ui/core/Paper';
import Popper             from '@material-ui/core/Popper';
import MenuItem           from '@material-ui/core/MenuItem';
import MenuList           from '@material-ui/core/MenuList';
import ListItemIcon       from '@material-ui/core/ListItemIcon';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import DefaultTheme from '@themes/DefaultTheme';
import { Colors } from '@themes/createMuiTheme';

export interface SplitButtonInterface {
  title: string;
  optionalArg?: string | number;
  onClick: (optionalArg) => void;
  icon?: JSX.Element | null;
}

interface SplitButtonProps {
  color?: Colors;
  fullWidth?: boolean;
  items: Array<SplitButtonInterface>;
  startIcon?: JSX.Element;
  variant?: 'contained' | 'outlined' | 'text';
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  arrowButton: {
    width: spacing(5)
  }
}));

export default function SplitButton({
  color,
  fullWidth = false,
  items,
  startIcon,
  variant
}: SplitButtonProps): JSX.Element {
  const classes = useStyles();

  const [open, setOpen]                   = React.useState(false);
  const anchorRef                         = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const buttonGroupIcon = items[selectedIndex].icon === null ? startIcon : items[selectedIndex].icon;

  return (
    <>
      <ButtonGroup
        color={color}
        variant={variant}
        ref={anchorRef}
        aria-label="split button"
        fullWidth={fullWidth}
      >
        <Button
          onClick={() => items[selectedIndex].onClick(items[selectedIndex].optionalArg)}
          startIcon={buttonGroupIcon}
          size="large"
        >
          {items[selectedIndex].title}
        </Button>
        <Button
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          className={classes.arrowButton}
          onClick={handleToggle}
          size="small"
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>

      <Popper open={open} anchorEl={anchorRef.current} role={undefined} style={{ zIndex: 100 }} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {items.map((item, index) => (item.title === 'divider' ? (
                    <Divider key={`d-${item.title}-${index}`} />
                  ) : (
                    <MenuItem
                      key={item.title}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {item.icon && (
                        <ListItemIcon>
                          {item.icon}
                        </ListItemIcon>
                      )}
                      {item.title}
                    </MenuItem>
                  )))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

SplitButton.defaultProps = {
  color: 'primary',
  variant: 'contained'
};
