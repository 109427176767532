import * as React  from 'react';
import SyncIcon    from '@material-ui/icons/Sync';
import LinkOffIcon from '@material-ui/icons/LinkOff';

import useDisconnectProvider       from '@components/Providers/common/useDisconnectProvider';
import { UserProvidersInterface  } from '@components/User/UserInterface';
import SplitButton                 from '@themes/styles/form/SplitButton';

interface ConnectedButtonGroupProps {
  onImportPlaylistsClick: (source: string) => void;
  userProvider: UserProvidersInterface;
}

export default function ConnectedButtonGroup({
  onImportPlaylistsClick,
  userProvider
}: ConnectedButtonGroupProps): JSX.Element {
  const { onDisconnect } = useDisconnectProvider();

  const handleImportPlaylistsClick = () => {
    onImportPlaylistsClick(userProvider.source);
  };

  const handleDisconnectClick = () => {
    onDisconnect(userProvider.source);
  };

  const groupItems = [
    {
      title: 'Import playlists',
      onClick: handleImportPlaylistsClick,
      icon: <SyncIcon />
    },
    {
      title: `Disconnect from ${userProvider.name}`,
      onClick: handleDisconnectClick,
      icon: <LinkOffIcon />
    }
  ];

  return (
    <SplitButton
      color="default"
      items={groupItems}
      fullWidth
    />
  );
}
