import * as React from 'react';

import Divider  from '@material-ui/core/Divider';
import List     from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid     from '@material-ui/core/Grid';
import {
  createStyles,
  makeStyles
} from '@material-ui/styles';

import ConnectedButtonGroup   from '@components/Providers/common/ConnectedButtonGroup';
import ProviderLogo           from '@components/Providers/common/ProviderLogo';
import {
  ProvidersInterface,
  ProviderComponentsType
} from '@components/Providers/ProvidersInterface';

import UserContext        from '@contexts/UserContext';
import DefaultTheme       from '@themes/DefaultTheme';
import PaddedPaper        from '@styles/PaddedPaper';
import Text               from '@styles/Text';
import UserProviderHelper from '@resources/helpers/UserProviderHelper';

interface ProviderListViewProps {
  onImportPlaylists: (source: string) => void;
  providers: ProvidersInterface;
  providerComponents: ProviderComponentsType;
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  heading: {
    marginBottom: spacing(5)
  },
  providerListItem: {
    margin: spacing(2, 0)
  }
}));

export default function ProviderListView({
  onImportPlaylists,
  providers,
  providerComponents
}: ProviderListViewProps): JSX.Element {
  const classes = useStyles();
  const user    = React.useContext(UserContext);

  const userProvidersHelper = new UserProviderHelper(user.providers);

  return (
    <PaddedPaper>
      <>
        <Text type="displayMd" className={classes.heading}>
          Providers
        </Text>

        <List>
          {Object.keys(providers).map((provider: string) => {
            const actionView = userProvidersHelper.isConnected(provider)
              ? (
                <ConnectedButtonGroup
                  onImportPlaylistsClick={onImportPlaylists}
                  userProvider={userProvidersHelper.fromSource(provider)}
                />
              ) : (
                providerComponents[provider]
              )
            ;

            return (
              <React.Fragment key={provider}>
                <ListItem className={classes.providerListItem}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <ProviderLogo providerSource={provider} height={40} />
                    </Grid>
                    <Grid item xs={12}>
                      {actionView}
                    </Grid>
                  </Grid>
                </ListItem>
                <Divider light component="li" />
              </React.Fragment>
            );
          })}
        </List>
      </>
    </PaddedPaper>
  );
}
