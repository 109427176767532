import * as React from 'react';

import Grid from '@material-ui/core/Grid';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

interface PageGridItemProps {
  children: JSX.Element;
  width?: number;
}

const useStyles = makeStyles(() => createStyles({
  root: {
    padding: '0 10px',
    margin: '0 auto 20px auto',
    height: '100%',
    width: '100%',
    maxWidth: (props: PageGridItemProps): number => props.width
  }
}));

export default function PageGridItem({ children, width }: PageGridItemProps): JSX.Element {
  const classes = useStyles({ width });

  return (
    <Grid item className={classes.root}>
      {children}
    </Grid>
  );
}

PageGridItem.defaultProps = {
  width: 700
};
