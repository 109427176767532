interface PlaylistImageInterface {
  height: number;
  width: number;
  url: string;
}

export interface PlaylistInterface {
  id: string;
  name: string;
  description?: string;
  createdAt: string;
  importSummaryId?: string;
  lastSyncedAt: string;
  orphan: boolean;
  providerSource: string;
  providerPlaylistLink: string;
  trackCount: number;
  images: Array<PlaylistImageInterface>;
}

export const orphanPlaylistSourceKey = 'orphan';
export const orphanPlaylistSourceOption = {
  label: 'Orphan Playlists',
  value: orphanPlaylistSourceKey
};
