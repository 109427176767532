import * as React from 'react';

import Hidden from '@material-ui/core/Hidden';
import Box    from '@material-ui/core/Box';
import {
  createStyles,
  makeStyles
} from '@material-ui/styles';

import DesktopMenuView                     from '@components/Header/DesktopMenuView';
import MobileMenuView                      from '@components/Header/MobileMenuView';
import QueueStatsIndicator                 from '@components/Header/QueueStatsIndicator';
import { AppStatsInterface }               from '@components/common/AppStatsInterface';
import { UserInterface }                   from '@components/User/UserInterface';
import NotificationsMenu                   from '@components/Notifications/NotificationsMenu';
import { NotificationCollectionInterface } from '@components/Notifications/NotificationCollectionInterface';

import DefaultTheme from '@themes/DefaultTheme';

interface MenuViewProps {
  appStats?: AppStatsInterface;
  isAuthenticated: boolean;
  isMobileMenuVisible: boolean;
  onMobileMenuToggle: () => void;
  menuItems: React.ReactNode;
  notificationCollection: NotificationCollectionInterface;
  user?: UserInterface;
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  mobileNotificationBox: {
    display: 'inline-block',
    marginRight: spacing(2)
  }
}));

export default function MenuView(props: MenuViewProps): JSX.Element {
  const {
    appStats,
    isAuthenticated,
    isMobileMenuVisible,
    onMobileMenuToggle,
    menuItems,
    notificationCollection,
    user
  } = props;

  const classes = useStyles();

  return (
    <>
      <Hidden xsDown>
        <DesktopMenuView>
          {menuItems}
        </DesktopMenuView>
      </Hidden>
      <Hidden smUp>
        {isAuthenticated && (
          <>
            <QueueStatsIndicator appStats={appStats} />
            <Box className={classes.mobileNotificationBox}>
              <NotificationsMenu notificationCollection={notificationCollection} />
            </Box>
          </>
        )}

        <MobileMenuView
          onMobileMenuToggle={onMobileMenuToggle}
          user={user}
          visible={isMobileMenuVisible}
        >
          {menuItems}
        </MobileMenuView>
      </Hidden>
    </>
  );
}
