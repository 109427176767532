import { withStyles } from '@material-ui/core/styles';
import InputBase      from '@material-ui/core/InputBase';

import DefaultTheme from '@themes/DefaultTheme';

export default withStyles((theme: DefaultTheme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    position: 'relative',
    border: '1px solid #ced4da',
    padding: '10px 26px 10px 12px'
  },
}))(InputBase);
