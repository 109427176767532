import * as React from 'react';

import Box            from '@material-ui/core/Box';
import Breadcrumbs    from '@material-ui/core/Breadcrumbs';
import Grid           from '@material-ui/core/Grid';
import Hidden         from '@material-ui/core/Hidden';
import Link           from '@material-ui/core/Link';
import useMediaQuery  from '@material-ui/core/useMediaQuery';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import OrphanTooltip              from '@components/Playlist/common/OrphanTooltip';
import PlaylistMenu               from '@components/Playlist/PlaylistMenu/PlaylistMenu';
import ProviderLogo               from '@components/Providers/common/ProviderLogo';
import SearchResource             from '@components/common/SearchResource';
import TracksTable                from '@components/Playlist/tracks/TracksTable';
import { PlaylistInterface }      from '@components/Playlist/PlaylistInterface';
import { PlaylistTrackInterface } from '@components/Playlist/PlaylistTrackInterface';

import DefaultTheme from '@themes/DefaultTheme';
import Text         from '@styles/Text';

// @ts-ignore
import DefaultPlaylistThumb  from 'images/default_playlist_thumb.svg';

interface PlaylistHomeViewProps {
  loading: boolean;
  order: string;
  page: number;
  searchTerm: string;
  sortBy: string;
  tracks: Array<PlaylistTrackInterface>;
  total: number;
  playlist: PlaylistInterface;
  onSearchTermChange: (term: string) => void;
  onSearchTermSubmit: () => void;
  onSortOrderChange: (sortBy: string, order: string) => void;
  onPageChange: (page: number, perPage: number) => void;
}

const useStyles = makeStyles(({ breakpoints, spacing }: DefaultTheme) => createStyles({
  tableGridItem: {
    marginBottom: spacing(5)
  },
  headingGrid: {
    marginBottom: spacing(5)
  },
  headerBox: {
    marginBottom: spacing(5)
  },
  headingPlaylistName: {
    overflow: 'hidden'
  },
  importedGridItem: {
    [breakpoints.up('sm')]: {
      textAlign: 'right'
    }
  },
  importedText: {
    marginBottom: spacing(3)
  },
  playlistImage: {
    width: '100%'
  },
  playlistMenuGridItem: {
    textAlign: 'right'
  },
  providerPlaylistLink: {
    textTransform: 'capitalize'
  },
  sectionTitle: {
    marginTop: spacing(2),
    marginBottom: spacing(2)
  }
}));

export default function PlaylistHomeView(props: PlaylistHomeViewProps): JSX.Element {
  const classes = useStyles();
  const isMobileResolution = useMediaQuery('(max-width:767px)');

  const {
    loading,
    order,
    onSearchTermChange,
    onSearchTermSubmit,
    onSortOrderChange,
    onPageChange,
    page,
    searchTerm,
    sortBy,
    total,
    tracks,
    playlist
  } = props;

  const playlistImage = playlist.images.length > 0 ? playlist.images[0].url : DefaultPlaylistThumb;

  let disconnectedLabel = '';
  let disconnectedTooltip = null;

  if (playlist.orphan) {
    disconnectedLabel = '(Disconnected)';
    disconnectedTooltip = (<OrphanTooltip />);
  }

  const importedFromBlock = playlist.providerSource === 'upload' ? (
    <Grid item xs={12} md={2} className={classes.importedGridItem}>
      <Text type="label" color="secondary" className={classes.importedText}>
        <Link href={`/imports/${playlist.importSummaryId}/summary`}>
          Uploaded from a CSV file.
        </Link>
      </Text>
    </Grid>
  ) : (
    <Grid item xs={12} md={2} className={classes.importedGridItem}>
      <Text type="label" color="secondary" className={classes.importedText}>
        {`Imported from ${disconnectedLabel}`}
        {disconnectedTooltip}
      </Text>

      <ProviderLogo providerSource={playlist.providerSource} height={40} />

      {playlist.providerPlaylistLink && (
        <Text type="label" className={classes.providerPlaylistLink}>
          <Link href={playlist.providerPlaylistLink} target="_blank" referrerPolicy="no-referrer">
            {`Open in ${playlist.providerSource}`}
          </Link>
        </Text>
      )}
    </Grid>
  );

  return (
    <>
      <Grid item xs={12} className={classes.headingGrid}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/playlists">
                <Text type="label">
                  Playlists
                </Text>
              </Link>

              <Text type="label" color="secondary">
                {playlist.name}
              </Text>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={4} md={2}>
            <img className={classes.playlistImage} src={playlistImage} alt={playlist.name} />
          </Grid>
          <Grid item xs={8} md={10}>
            <Grid container alignItems="flex-start">
              <Grid item xs={10}>
                <Box className={classes.headerBox}>
                  <Text type="heading" className={classes.headingPlaylistName}>
                    {playlist.name}
                  </Text>

                  <Text type="displaySm" color="secondary" weight={500}>
                    {playlist.trackCount}
                    {` track${playlist.trackCount > 1 ? 's' : ''}`}
                  </Text>

                  <Text type="label" color="lightGrey">
                    {`Last updated ${playlist.lastSyncedAt}. Imported on ${playlist.createdAt}.`}
                  </Text>
                </Box>
              </Grid>
              {!isMobileResolution && importedFromBlock}
            </Grid>

            {playlist.description && (
              <Text type="displayXs">
                {playlist.description}
              </Text>
            )}
          </Grid>

          {isMobileResolution && importedFromBlock}
        </Grid>
      </Grid>

      <Hidden smUp>
        <Grid item xs={12} className={classes.playlistMenuGridItem}>
          <PlaylistMenu
            playlist={playlist}
            variant="Button"
          />
        </Grid>
      </Hidden>

      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="space-between" spacing={3} wrap="wrap">
          <Grid item>
            <Text type="displaySm" className={classes.sectionTitle}>
              Tracks
            </Text>
          </Grid>
          <Grid item xs={9} sm={4}>
            <SearchResource
              onSearchTermChange={onSearchTermChange}
              onSearchTermSubmit={onSearchTermSubmit}
              placeholder="Filter by term"
              searchTerm={searchTerm}
            />
          </Grid>
          <Hidden xsDown>
            <Grid item sm={6} md={7} className={classes.playlistMenuGridItem}>
              <PlaylistMenu
                playlist={playlist}
                variant="Button"
              />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.tableGridItem}>
        <Grid container spacing={3}>
          <TracksTable
            loading={loading}
            onSortOrderChange={onSortOrderChange}
            onPageChange={onPageChange}
            order={order}
            page={page}
            playlistId={playlist.id}
            rows={tracks}
            sortBy={sortBy}
            total={total}
          />
        </Grid>
      </Grid>
    </>
  );
}
