import * as React from 'react';

import ResetPasswordView            from '@components/Auth/ResetPasswordView';
import { ResetPasswordErrorsType }  from '@components/Auth/ResetPasswordErrorsType';

import { PASSWORD_MIN_LENGTH } from '@resources/validationRules';

interface ResetPasswordProps {
  formAction: string;
  csrfToken: string;
  errors: ResetPasswordErrorsType;
  resetPasswordToken: string;
}

interface ResetPasswordState {
  errors: ResetPasswordErrorsType;
  password: string;
  passwordConfirmation: string;
}

const errorsInitialState: ResetPasswordErrorsType = {
  password: '',
  passwordConfirmation: '',
  resetPasswordToken: ''
};

export default class ResetPassword extends React.Component<ResetPasswordProps, ResetPasswordState> {
  private formRef = React.createRef<HTMLFormElement>();

  constructor(props: ResetPasswordProps) {
    super(props);

    this.state = {
      errors: {
        ...errorsInitialState,
        ...props.errors
      },
      password: '',
      passwordConfirmation: ''
    };
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { id, value } = event.currentTarget;

    this.setState((prevState: ResetPasswordState): ResetPasswordState => ({
      ...prevState,
      [id]: value
    }));
  };

  handleSave = (event: React.SyntheticEvent): boolean => {
    event.preventDefault();

    if (!this.isValid()) {
      return false;
    }

    if (this.formRef.current) {
      this.formRef.current.submit();
    }

    return true;
  };

  isValid(): boolean {
    const {
      password,
      passwordConfirmation
    } = this.state;

    const errors = { ...errorsInitialState };

    let isValid = true;

    if (password.length < PASSWORD_MIN_LENGTH) {
      errors.password = `Password is too short. It should contain at least ${PASSWORD_MIN_LENGTH} characters`;
      isValid = false;
    }

    if (password.length > 0 && password !== passwordConfirmation) {
      errors.passwordConfirmation = 'Passwords don\'t match.';
      isValid = false;
    }

    this.setState({ errors });

    return isValid;
  }

  render(): React.ReactNode {
    const {
      errors,
      password,
      passwordConfirmation
    } = this.state;

    const {
      csrfToken,
      formAction,
      resetPasswordToken
    } = this.props;

    return (
      <ResetPasswordView
        csrfToken={csrfToken}
        formAction={formAction}
        errors={errors}
        onChange={this.handleChange}
        onSave={this.handleSave}
        password={password}
        passwordConfirmation={passwordConfirmation}
        resetPasswordToken={resetPasswordToken}
        ref={this.formRef}
      />
    );
  }
}
