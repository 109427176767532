import * as React from 'react';

import Box          from '@material-ui/core/Box';
import Breadcrumbs  from '@material-ui/core/Breadcrumbs';
import Grid         from '@material-ui/core/Grid';
import Link         from '@material-ui/core/Link';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import { ImportSummaryInterface } from '@components/Summaries/Import/ImportSummaryInterface';
import ImportedBlobSummary        from '@components/Summaries/Import/common/ImportedBlobSummary';

import DefaultTheme from '@themes/DefaultTheme';
import Text         from '@styles/Text';

interface ImportSummaryViewProps {
  summary: ImportSummaryInterface;
}

const useStyles = makeStyles(({ breakpoints, spacing }: DefaultTheme) => createStyles({
  headingGrid: {
    marginBottom: spacing(5)
  },
  headerBox: {
    marginBottom: spacing(5)
  },
  exportedGridItem: {
    marginBottom: spacing(5),
    [breakpoints.up('sm')]: {
      textAlign: 'right'
    }
  },
  exportedText: {
    marginBottom: spacing(3)
  }
}));

export default function ImportSummaryView({ summary }: ImportSummaryViewProps): JSX.Element {
  const classes = useStyles();
  const blob = summary.blobs[0];

  return (
    <>
      <Grid item xs={12} className={classes.headingGrid}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/playlists">
                <Text type="label">
                  Playlists
                </Text>
              </Link>

              <Text type="label" color="secondary">
                Import Summary
              </Text>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="flex-start">
              <Grid item xs={10}>
                <Box className={classes.headerBox}>
                  <Text type="heading">
                    Import Summary
                  </Text>
                </Box>
              </Grid>
              <Grid item xs={12} md={10}>
                <Box className={classes.headerBox}>
                  {blob.playlistId && (
                    <Text type="displayXs">
                      <a href={`/playlists/${blob.playlistId}`}>
                        View playlist
                      </a>
                    </Text>
                  )}

                  <Text type="label" color="secondary">
                    {`Uploaded file: `}
                    <i>{blob.filename}</i>
                  </Text>
                </Box>

                <ImportedBlobSummary blob={blob} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
