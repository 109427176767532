import * as React from 'react';

import {
  createStyles,
  makeStyles
} from '@material-ui/styles';

import DefaultTheme from '@themes/DefaultTheme';
import SearchInput  from '@themes/styles/form/SearchInput';

interface SearchResourceProps {
  onSearchTermChange: (term: string) => void;
  onSearchTermSubmit: () => void;
  placeholder: string;
  searchTerm: string;
}

const useStyles = makeStyles(({ breakpoints }: DefaultTheme) => createStyles({
  root: {
    [breakpoints.down('md')]: {
      width: '100%'
    },
    [breakpoints.up('lg')]: {
      width: 'auto'
    }
  }
}));

export default function SearchResource({
  onSearchTermChange,
  onSearchTermSubmit,
  placeholder,
  searchTerm
}: SearchResourceProps): JSX.Element {
  const classes = useStyles();

  // const [isInvalid, setIsInvalid] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;
    onSearchTermChange(value);
  };

  const handleSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();

    if (event.type === 'mousedown') {
      return;
    }

    // if (searchTerm.length === 0) {
    //   setIsInvalid(true);
    //   return;
    // }
    //
    // setIsInvalid(false);

    onSearchTermSubmit();
  };

  return (
    <form onSubmit={handleSubmit}>
      <SearchInput
        className={classes.root}
        onChange={handleChange}
        onSubmit={handleSubmit}
        placeholder={placeholder}
        term={searchTerm}
      />
    </form>
  );
}
