import * as React from 'react';
import Markdown from 'markdown-to-jsx';
import Grid from '@material-ui/core/Grid';

import markdownConfig    from '@resources/markdownConfig';
import FaqPageMarkdown from '@docs/faq.md';

export default function Faq(): JSX.Element {
  return (
    <Grid item xs={12}>
      <Markdown options={markdownConfig}>
        {FaqPageMarkdown}
      </Markdown>
    </Grid>
  );
}
