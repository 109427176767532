import * as React from 'react';

import FlashContext               from '@contexts/FlashContext';
import ProviderApi                from '@api/ProviderApi';
import { defaultApiErrorMessage } from '@api/BaseApi';

interface useDisconnectProviderInterface {
  onDisconnect: (source: string) => void;
}

const useDisconnectProvider = (): useDisconnectProviderInterface => {
  const flash = React.useContext(FlashContext);

  const handleOnDisconnect = React.useCallback((source): void => {
    ProviderApi
      .disconnectProvider(source)
      .then(() => {
        window.location.href = '/';
      })
      .catch((res) => {
        const message = res.response ? res.response.body.error : defaultApiErrorMessage;
        const error = ['alert', message];
        flash.flashMessage(error);
      })
    ;
  }, []);

  return {
    onDisconnect: (source: string) => handleOnDisconnect(source)
  };
};

export default useDisconnectProvider;
