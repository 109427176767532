export default class LocationHelper {
  loc = null

  constructor(loc = window.location) {
    this.loc = loc;
  }

  getParam(name: string): string {
    const params = new URLSearchParams((this.loc.search));
    return params.get(name);
  }
}
