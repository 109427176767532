import * as React from 'react';

import Badge              from '@material-ui/core/Badge';
import Button             from '@material-ui/core/Button';
import ClickAwayListener  from '@material-ui/core/ClickAwayListener';
import IconButton         from '@material-ui/core/IconButton';
import NotificationsIcon  from '@material-ui/icons/Notifications';
import Grow               from '@material-ui/core/Grow';
import Paper              from '@material-ui/core/Paper';
import Popper             from '@material-ui/core/Popper';

import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import { NotificationInterface }  from '@components/Notifications/NotificationInterface';
import NotificationList           from '@components/Notifications/NotificationList';

import DefaultTheme from '@themes/DefaultTheme';
import Text         from '@styles/Text';

interface NotificationsMenuViewProps {
  notifications: Array<NotificationInterface>;
  onDismissClick: (notificationId: string) => void;
  onDismissAllClick: () => void;
  unreadCount: number;
}

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  listPaper: {
    width: 360,
  },
  title: {
    padding: spacing(3),
    paddingTop: spacing(2),
    paddingBottom: 0
  }
}));

export default function NotificationsMenuView({
  notifications,
  onDismissClick,
  onDismissAllClick,
  unreadCount
}: NotificationsMenuViewProps): JSX.Element {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const anchorRef       = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <IconButton
        aria-label={`show ${unreadCount} new notifications`}
        color="inherit"
        onClick={handleToggle}
        ref={anchorRef}
      >
        <Badge badgeContent={unreadCount} color="primary">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'center bottom' }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <Paper className={classes.listPaper} elevation={5}>
                <Text type="displayXs" className={classes.title} weight={400}>
                  Notifications
                </Text>

                <NotificationList
                  notifications={notifications}
                  onDismissClick={onDismissClick}
                />

                {notifications.length > 0 && (
                  <Button
                    color="secondary"
                    onClick={onDismissAllClick}
                    fullWidth
                  >
                    Clear all
                  </Button>
                )}
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </>
  );
}
