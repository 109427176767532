import * as React from 'react';

import Grid from '@material-ui/core/Grid';

import LoadingCard                from '@components/common/cards/LoadingCard';
import PlaylistCard               from '@components/ManagePlaylists/ViewModes/PlaylistCard';
import NoPlaylistsMessage         from '@components/ManagePlaylists/common/NoPlaylistsMessage';
import { PlaylistInterface }      from '@components/Playlist/PlaylistInterface';
import { ViewModePropsInterface } from '@components/ManagePlaylists/ViewModes/ViewModePropsInterface';

export default function CardsView({
  inEditMode,
  loading,
  onEditSelectItem,
  playlists,
  selectedPlaylistIds
}: ViewModePropsInterface): JSX.Element {
  return loading ? (
    <>
      {[1, 2, 3, 4].map((idx: number): JSX.Element => (
        <Grid item xs={12} sm={6} md={3} key={`lcard-${idx}`}>
          <LoadingCard />
        </Grid>
      ))}
    </>
  ) : (
    <>
      {playlists.length > 0
        ? playlists.map((playlist: PlaylistInterface): JSX.Element => (
          <Grid item xs={12} sm={6} md={3} key={playlist.id}>
            <PlaylistCard
              inEditMode={inEditMode}
              isSelected={selectedPlaylistIds.includes(playlist.id)}
              onEditSelectItem={onEditSelectItem}
              playlist={playlist}
            />
          </Grid>
        ))
        : (
          <NoPlaylistsMessage />
        )}
    </>
  );
}
