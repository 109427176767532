import * as React from 'react';

import Box            from '@material-ui/core/Box';
import Button         from '@material-ui/core/Button';
import Fade           from '@material-ui/core/Fade';
import FormControl    from '@material-ui/core/FormControl';
import Grid           from '@material-ui/core/Grid';
import TextField      from '@material-ui/core/TextField';
import useMediaQuery  from '@material-ui/core/useMediaQuery';
import {
  makeStyles,
  createStyles
} from '@material-ui/styles';

import DefaultTheme       from '@themes/DefaultTheme';
import FormHelperText     from '@styles/form/FormHelperText';
import PageGridItem       from '@styles/PageGridItem';
import Text               from '@styles/Text';

import { EMAIL_REGEX } from '@resources/validationRules';

const useStyles = makeStyles(({ spacing }: DefaultTheme) => createStyles({
  form: {
    marginTop: spacing(5)
  },
  headingBox: {
    marginBottom: spacing(2)
  },
  submitBtn: {
    marginTop: spacing(2)
  }
}));

interface ResendConfirmationEmailProps {
  csrfToken: string;
  email: string;
  errors: { email?: string, confirmationToken?: string };
  formAction: string;
}

export default function ResendConfirmationEmail({
  csrfToken,
  email,
  errors,
  formAction
}: ResendConfirmationEmailProps): JSX.Element {
  const [inputEmail, setInputEmail] = React.useState(email);
  const [inputEmailError, setEmailError] = React.useState('');
  const formRef = React.useRef(null);

  const classes = useStyles();
  const isMobileResolution = useMediaQuery('(max-width:767px)');

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void  => {
    setInputEmail(event.currentTarget.value);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>): boolean => {
    if (inputEmail.length === 0) {
      setEmailError('Enter your email.');
      event.preventDefault();
      return false;
    }

    if (inputEmail.length > 0 && EMAIL_REGEX.test(inputEmail) === false) {
      setEmailError('Invalid email provided.');
      event.preventDefault();
      return false;
    }

    if (formRef.current && inputEmail.length > 0) {
      formRef.current.submit();
    }

    return true;
  };

  const hasError = errors.email?.length > 0 || errors.confirmationToken?.length > 0 || inputEmailError.length > 0;

  return (
    <PageGridItem>
      <form
        action={formAction}
        className={classes.form}
        method="post"
        onSubmit={onSubmit}
        ref={formRef}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className={classes.headingBox}>
              <Text type="heading">
                Resend confirmation instructions
              </Text>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              error={hasError}
              fullWidth
            >
              {errors.confirmationToken?.length > 0 && (
                <Box className={classes.headingBox}>
                  <Fade in>
                    <FormHelperText>
                      {`Confirmation token ${errors.confirmationToken}.`}
                    </FormHelperText>
                  </Fade>
                </Box>
              )}

              <TextField
                error={errors.email?.length > 0}
                name="user[email]"
                id="email"
                label="Email Address"
                type="email"
                onChange={handleOnChange}
                value={inputEmail}
                variant="outlined"
                required
              />

              {inputEmailError.length > 0 && (
                <Fade in>
                  <FormHelperText>
                    {inputEmailError}
                  </FormHelperText>
                </Fade>
              )}

              {errors.email?.length > 0 && (
                <Fade in>
                  <FormHelperText>
                    {`Email ${errors.email}.`}
                  </FormHelperText>
                </Fade>
              )}
            </FormControl>

            <input type="hidden" name="authenticity_token" value={csrfToken} />
          </Grid>

          <Grid item xs={12}>
            <Button
              className={classes.submitBtn}
              color="primary"
              fullWidth={isMobileResolution}
              onClick={onSubmit}
              type="submit"
              variant="contained"
              size="large"
            >
              Resend confirmation instructions
            </Button>
          </Grid>
        </Grid>
      </form>
    </PageGridItem>
  );
}
